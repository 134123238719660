<template>
  <div :class="'article-list' + (big ? ' big' : '') + (compact ? ' compact' : '')" v-if="articleList.length > 0">
    <h2 class="caption" v-if="title">{{ title }}</h2>
    <div class="articles">
      <div v-for="(article, index) in articleList" :key="article.id" :style="'order: ' + (index * 10 + 10) + ';'">
        <a-link class="image" :href="article.path" :style="'background-image: url(' + (!index && big ? getImageUrl(article, 'header') : getImageUrl(article, 'listing')) + ')'"></a-link>
        <div class="text">
          <div class="title">
            <h2>
              <a-link :href="article.path">{{ article.title }}</a-link>
            </h2>
            <div class="attributes">
              <div v-if="article.audio.length > 0" class="audio icon-headphones"></div>
              <div v-if="article.youtube_video_id" class="video icon-play"></div>
            </div>
          </div>
          <div class="teaser" v-if="!compact" v-html="article.teaser"></div>
          <div class="links">
            <a-link :href="article.path">
              <span class="chevron icon-chevron-right"></span>
              {{ article.link_text }}
            </a-link>
          </div>
        </div>
      </div>
      <div class="article" v-if="!!$slots.default && order % 2 === 0" style="margin: 0; padding: 0; order: 999;"></div>
      <div class="article feed" v-if="!!$slots.default" :style="'order: ' + order + ';'">
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>

<script>

import ContentService from '../services/content'

export default {
  props: {
    ids: {
      type: Array,
      default: () => []
    },
    articles: {
      type: Array,
      default: () => []
    },
    big: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    position: {
      type: [Number, String],
      default: 0
    }
  },
  data: () => ({
    articleList: []
  }),
  computed: {
    order () {
      return (parseInt(this.position) * 10 + parseInt(this.position))
    }
  },
  watch: {
    ids: {
      immediate: true,
      handler (to) {
        if (to.length > 0) {
          ContentService.getPublishedArticles('filter[id][in]=' + to.join(',')).then(result => {
            this.articleList = result.data
          })
        }
      }
    },
    articles: {
      immediate: true,
      handler (to) {
        this.articleList = to
      }
    }
  },
  methods: {
    getImageUrl (article, type) {
      const image = article.image.data.thumbnails.find(thumbnail => thumbnail.key === type)

      return image ? image.url : ''
    },
    openArticle (article) {
      this.$router.push(article.path)
    }
  }
}

</script>

<style lang="scss" scoped>

.article-list {
  .caption {
    width: 100%;
  }

  .articles {
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin: 50px 0 0 0;
      position: relative;

      &.feed {
        margin-left: -10px;
        margin-right: -10px;
      }

      .head {
        position: relative;
        margin: 0 0 20px 0;
      }

      .image {
        display: block;
        height: 180px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #eee;
        background-position: center center;
        cursor: pointer;
      }

      .text {
        position: relative;
      }

      .title {
        display: flex;
        text-align: left;
        justify-content: space-between;

        & > :first-child {
          font-size: 24px;
          line-height: 32px;
          margin: 0;
          padding: 20px 20px 0 0;
          cursor: pointer;
        }

        .attributes {
          display: flex;

          .audio,
          .video {
            background-color: #014E9E;
            color: #fff;
            margin-left: 3px;
            width: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            font-weight: normal;
          }
        }
      }

      .teaser {
        padding-top: 20px;
        text-align: left;
      }

      .links {
        margin: 20px 0 0 0;
        font-weight: bold;
        text-align: left;
        font-family: 'FrutigerLTStdCn';

        .chevron {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
  }

  &.big {
    .articles {
      & > * {
        &:first-child {
          .image {
            height: 280px;
          }

          .title {
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            right: 30px;
            transform: translate(0, -100%);
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    &.compact {
      .articles {
        margin-left: -10px;
        margin-right: -10px;

        & > * {
          width: 50%;
          padding: 0 10px;

          &.feed {
            margin-left: 0;
            margin-right: 0;
          }

          .image {
            height: 160px;
          }
        }
      }
    }
  }

  @media (min-width: 840px) {
    .articles {
      margin: 0 -20px;

      & > * {
        padding: 0 20px;
        width: 50%;

        &.feed {
          margin-left: 0;
          margin-right: 0;

          &::v-deep {
            .instagram-feed {
              min-height: 100%;
            }
          }
        }

        .image {
          height: 260px;
        }

        .title {
          & > :first-child {
            padding: 30px 30px 0 30px;
          }

          .attributes {
            .audio,
            .video {
              width: 50px;
              height: 50px;
              line-height: 50px;
              font-size: 18px;
            }
          }
        }

        .teaser,
        .links {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }

    &.big {
      .articles {
        & > * {
          margin-top: 80px;

          &:first-child {
            width: 100%;
            margin-top: 50px;

            .image {
              height: 600px;
            }

            .text {
              position: absolute;
              left: 20px;
              right: 50%;
              bottom: 0;
              background-color: #fff;
              margin-right: 20px;

              .title {
                transform: translate(0, 0);
                position: static;
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    &.compact {
      .articles {
        & > * {
          .title {
            & > :first-child {
              padding-left: 0;
              padding-right: 0;
            }
          }

          .teaser,
          .links {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

</style>
