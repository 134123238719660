<template>
  <div class="loader">
    <div class="spinner"></div>
    <div class="text" v-if="text">{{ text }}</div>
  </div>
</template>

<script>

export default {
  props: {
    text: {}
  }
}

</script>

<style scoped lang="scss">

@keyframes Loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  flex-wrap: wrap;

  .spinner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: #004e9e;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    animation: Loader 1.2s linear infinite;
  }

  .text {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
}

</style>
