<template>
  <div class="page">
    <layout-category :data="category" />
  </div>
</template>

<script>

import LayoutCategory from '../components/Layout/Category'

export default {
  components: {
    'layout-category': LayoutCategory
  },
  props: {
    category: {
      type: Object,
      default: () => ({})
    }
  }
}

</script>
