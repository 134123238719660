import env from '../helpers/env'
import locale from './de-DE'

export default {
  id: env.locale,
  dayNames: locale.dayNames,
  monthNames: locale.monthNames,
  decimalPoint: locale.decimalPoint,
  formatDateShort: locale.formatDateShort,
  formatDateMedium: locale.formatDateMedium,
  formatDateLong: locale.formatDateLong,
  formatDateLocalISO: locale.formatDateLocalISO,
  formatTimeShort: locale.formatTimeShort,
  formatTimeLong: locale.formatTimeLong,
  formatTimeLocalISO: locale.formatTimeLocalISO,
  formatDateTimeShort: locale.formatDateTimeShort,
  formatDateTimeMedium: locale.formatDateTimeMedium,
  formatDateTimeLong: locale.formatDateTimeLong,
  formatDateTimeLocalISO: locale.formatDateTimeLocalISO
}
