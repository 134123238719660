<template>
  <button :class="classes" v-on="$listeners" :disabled="disabled">
    <slot name="default" />
  </button>
</template>

<script>

export default {
  props: {
    primary: {
      default: false,
      type: Boolean
    },
    dark: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    classes () {
      const classes = ['button']

      if (this.dark) {
        classes.push('dark')
      }

      if (this.primary) {
        classes.push('primary')
      }

      if (this.disabled) {
        classes.push('disabled')
      }

      return classes.join(' ')
    }
  }
}

</script>

<style lang="scss" scoped>

.button {
  background-color: #fff;
  border-radius: 6px;
  border: none;
  color: #014E9E;
  outline: 0;
  text-transform: uppercase;
  padding: 0 30px;
  font-family: 'FrutigerLTStdBoldCn';
  font-size: 16px;
  height: 60px;
  line-height: 60px;
  border-width: 1px;
  border-color: #014E9E;
  border-style: solid;
  cursor: pointer;

  &.primary {
    background-color: #014E9E;
    color: #fff;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.dark {
    background-color: #fff;
    border-color: #fff;
    color: #014E9E;
  }

  &:active {
    opacity: 0.8;
  }
}

</style>
