<template>
  <div :class="classes">
    <slot name="default" />
  </div>
</template>

<script>

export default {
  props: {
    sub: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      const classes = ['headline']

      if (this.sub) {
        classes.push('sub')
      }

      return classes
    }
  }
}

</script>

<style lang="scss" scoped>

.headline {
  font-family: 'FrutigerLTStdCn';
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #014E9E;
  margin: 0 0 10px 0;

  &.sub {
    font-size: 18px;
    line-height: 22px;
  }

  & + .headline.sub {
    margin-top: -6px;
    margin-bottom: 16px;
  }

  span {
    display: block;
  }

  @media (min-width: 840px) {
    span {
      display: inline;
      margin-right: 7px;
    }
  }

  @media (min-width: 1080px) {
    & {
      font-size: 38px;
      line-height: 44px;
    }

    &.sub {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

</style>
