<template>
  <div class="share">
    <a :class="'service ' + service.key" v-for="service in services" @click="share(service)" :key="service.key" :title="service.title">
      <span :class="'icon-share-' + service.icon" :style="'color: #' + service.color"></span>
    </a>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String
    }
  },
  data: () => ({
    services: [
      {
        key: 'whatsapp',
        title: 'Auf WhatsApp teilen',
        icon: 'whatsapp',
        color: '25D366',
        getUrl (url, title) {
          return 'https://api.whatsapp.com/send?text=' + encodeURIComponent(title + ' ' + url)
        }
      },
      {
        key: 'twitter',
        title: 'Auf Twitter teilen',
        icon: 'twitter',
        color: '55acee',
        getUrl (url, title) {
          return 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(title)
        }
      },
      {
        key: 'mail',
        title: 'Per Mail teilen',
        icon: 'mail',
        color: 'dd4b39',
        getUrl (url, title) {
          return 'mailto:?subject=' + title + '&body=' + url
        }
      },
      {
        key: 'facebook',
        title: 'Auf Facebook teilen',
        icon: 'facebook',
        color: '3b5999',
        getUrl (url) {
          return 'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(url)
        }
      }
    ]
  }),
  methods: {
    share (service) {
      window.open(service.getUrl(window.location.href, this.title))
    }
  }
}

</script>

<style lang="scss" scoped>

.share {
  display: flex;
  flex-wrap: wrap;

  .service {
    font-size: 30px;
    margin: 0 20px 0 0;
  }
}

</style>
