<template>
  <div>
    <a-box>
      <h1 v-html="data.title"></h1>
      <p v-if="data.description" v-html="data.description"></p>
    </a-box>
    <a-box>
      <a-article-list big :articles="articles" />
    </a-box>
  </div>
</template>

<script>

import ContentService from '../../services/content'
import ArticleList from '../ArticleList'

export default {
  components: {
    'a-article-list': ArticleList
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    articles: []
  }),
  watch: {
    data: {
      immediate: true,
      handler (to) {
        ContentService.getCategoryRelatedArticles([{ id: to.id }]).then(result => {
          this.articles = result[0].articles
        })
      }
    }
  }
}

</script>
