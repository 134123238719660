<template>
  <div class="wrapper">
    <div class="left">
      <div :class="menuClasses" v-if="showMenu" @click="menu">
        <div></div>
        <span>Menü</span>
      </div>
      <div class="menu" v-if="showMenu" @click="closeMenu">
        <div class="menu-background" @click="$event.stopPropagation()">
          <a-menu @click="closeMenu" />
        </div>
      </div>
    </div>
    <div class="center">
      <template v-for="link in links">
        <a v-if="link.area.indexOf('header-center') >= 0" :key="link.id" :class="link.icon" @click="openLink(link)">
          <template v-if="link.icon === 'logo-agila'">
            <img src="../assets/logo.svg" alt="AGILA Haustierversicherung" />
          </template>
          <template v-else>
            {{ link.name }}
          </template>
        </a>
      </template>
    </div>
    <div class="right">
      <template v-for="link in links">
        <a v-if="link.area.indexOf('header-right') >= 0" :key="link.id" :class="link.icon" @click="openLink(link)">
          <template v-if="link.icon === 'logo-ein-herz-fuer-tiere'">
            <img src="../assets/Logo_EHfT.png" alt="Ein Herz für Tiere" />
            <span class="text-short">Partner</span>
            <span class="text-long">{{ link.name }}</span>
          </template>
          <template v-else>
            {{ link.name }}
          </template>
        </a>
      </template>
    </div>
  </div>
</template>

<script>

import ContentService from '../services/content'
import Menu from './Menu'

export default {
  components: {
    'a-menu': Menu
  },
  props: {
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    links: [],
    menuClasses: ['menu-button']
  }),
  methods: {
    goToHome () {
      if (process.env.NODE_ENV === 'production') {
        document.location.href = 'https://agila.de'
      } else {
        this.$router.push({
          path: '/'
        })

        this.menuClose()
      }
    },
    openLink (link) {
      if (link.new_window) {
        window.open(link.url)
      } else if (/^[a-z-]+:\/\//.test(link.url)) {
        window.location.href = link.url
      } else {
        this.$router.push({
          path: link.url
        }).catch(() => {})
      }
    },
    menu () {
      if (!this.closeMenu()) {
        this.openMenu()
      }
    },
    openMenu () {
      this.menuClasses.push('open')

      document.body.style.overflow = 'hidden'

      document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    },
    closeMenu () {
      const index = this.menuClasses.indexOf('open')

      if (index < 0) {
        return false
      }

      this.menuClasses.splice(index, 1)

      document.body.style.overflow = 'auto'

      return true
    }
  },
  mounted () {
    ContentService.getItems('links', 'filter[area][rlike]=%header-%&filter[environment][like]=' + process.env.NODE_ENV).then(result => {
      this.links = result.data
    })
  }
}

</script>

<style lang="scss" scoped>

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 0 0;
  height: 80px;

  & > * {
    display: flex;
    width: 20%;
    height: 100%;
    align-items: center;
  }

  & > .left {
    justify-content: flex-start;
  }

  & > .center {
    width: 60%;
    justify-content: center;
  }
  & > .right {
    justify-content: flex-end;
  }

  .menu-button {
    position: relative;
    z-index: 5;
    padding-left: 30px;
    height: 55px;
    cursor: pointer;
    text-align: center;

    div {
      height: 3px;
      background-color: #666;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 0;
      width: 30px;
      margin-top: -1px;
      border-radius: 1px;
      transition: background-color 300ms;

      &::before,
      &::after {
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        content: '';
        overflow: hidden;
        height: inherit;
        background-color: #666;
        border-radius: inherit;
        transition: top 200ms 200ms, bottom 200ms 200ms, transform 200ms, background-color 200ms;
      }

      &::after {
        top: auto;
        bottom: -10px;
      }
    }

    span {
      display: none;
      color: #fff;
      font-family: 'FrutigerLTStdCn';
      font-size: 24px;
    }

    &.open {
      div {
        background-color: transparent;
      }

      div::before,
      div::after {
        transition: top 200ms, bottom 200ms, transform 200ms 200ms, background-color 200ms;
        background-color: white;
      }

      div::before {
        top: 0;
        transform: rotate(45deg);
      }

      div::after {
        bottom: 0;
        transform: rotate(-45deg);
      }

      span {
        display: block;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        height: 85px;
        line-height: 55px;
        padding: 15px 0 0 0;
        background-color: #014E9E;
      }
    }

    @media (min-width: 840px) {
      height: 65px;
      line-height: 65px;

      div {
        height: 2px;
        width: 20px;

        &::before {
          top: -5px;
        }

        &::after {
          bottom: -5px;
        }
      }

      span {
        font-size: 16px;
        display: inline;
        color: #014E9E;
        opacity: 1;
      }

      &.open {
        div::before,
        div::after {
          background-color: #666;
        }

        span {
          position: static;
          height: 65px;
          line-height: 65px;
          background-color: transparent;
          padding: 0;
        }
      }
    }
  }

  .menu {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    min-height: 200px;
    display: none;
    padding: 85px 0 0 0;
    overflow: auto;

    .menu-background {
      background-color: #014E9E;
      padding: 0 5px;
    }

    @media (min-width: 840px) {
      top: 110px;
      padding-top: 0;
    }
  }

  .menu-button.open + .menu {
    display: block;
  }

  .logo-agila {
    height: 55px;
    text-align: center;
    cursor: pointer;

    img {
      height: 100%;
    }

    @media (min-width: 840px) {
      height: 65px;
    }
  }

  .logo-ein-herz-fuer-tiere {
    display: flex;
    flex-wrap: wrap;
    width: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #444;

    img {
      height: 40px;
      width: auto;
    }

    .text-short {
      display: block;
      width: 100%;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
    }

    .text-long {
      display: none;
    }

    @media (min-width: 840px) {
      width: 100px;

      img {
        height: 60px;
      }

      .text-short {
        display: none;
      }

      .text-long {
        display: block;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  @media (min-width: 840px) {
    height: 110px;
  }
}

</style>
