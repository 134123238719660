import Vue from 'vue'
import VueRouter from 'vue-router'
import Base from '../views/Base'
import Page from '../views/Page'
import Category from '../views/Category'
import Article from '../views/Article'
import ContentService from '../services/content'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location, resolve, reject) {
  if (resolve || reject) {
    return originalPush.call(this, location, resolve, reject)
  }

  return originalPush.call(this, location).catch(error => {
    if (VueRouter.isNavigationFailure(error)) {
      return error
    }

    return Promise.reject(error)
  })
}

Vue.use(VueRouter)

const data = {
  loading: true
}

let basePath = process.env.NODE_ENV === 'production' ? '/' : '/health-campaign/'

if (process.env.VUE_APP_PUBLIC_SUBPATH) {
  basePath += process.env.VUE_APP_PUBLIC_SUBPATH
}

const router = new VueRouter({
  base: basePath,
  mode: 'history',
  routes: [{
    name: 'Base',
    path: '*',
    component: Object.assign(Base, {
      data: () => data
    })
  }]
})

ContentService.getPublishedPages().then(result => {
  result.data.forEach(page => {
    router.addRoute({
      path: page.path,
      name: page.title,
      component: Page,
      props: {
        page
      }
    })
  })

  data.loading = false
})

ContentService.getPublishedCategories().then(result => {
  result.data.forEach(category => {
    router.addRoute({
      path: category.path,
      name: category.title,
      component: Category,
      props: {
        category
      }
    })
  })
})

ContentService.getPublishedArticles().then(result => {
  result.data.forEach(article => {
    router.addRoute({
      path: article.path,
      name: article.title,
      component: Article,
      props: {
        article
      }
    })
  })
})

router.afterEach(to => {
  document.body.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })

  document.querySelector('link[rel="canonical"]').setAttribute('href', String(process.env.VUE_APP_FULL_URL).replace(/\/+$/, '') + to.path)
})

export default router
