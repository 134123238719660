<template>
  <div class="expert-list">
    <div v-for="expert in experts" :key="expert.id">
      <div class="image">
        <a-image :data="expert.image" thumbnail="avatar" />
      </div>
      <div class="text">
        <div class="name">
          {{ expert.firstname }} {{ expert.lastname }}
        </div>
        <div class="description" v-html="expert.description"></div>
      </div>
    </div>
  </div>
</template>

<script>

import ContentService from '../services/content'

export default {
  props: {
    ids: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    experts: []
  }),
  watch: {
    ids: {
      immediate: true,
      handler (to) {
        ContentService.getItems('authors', 'status=published&fields=*,image.*' + (to.length ? '&filter[id][in]=' + to.join(',') : '')).then(result => {
          this.experts = result.data
        })
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.expert-list {
  & > * {
    margin-top: 50px;

    &:first-child {
      margin-top: 0;
    }

    .image {
      display: block;
      margin: 0 auto;
      width: 180px;
      border-radius: 50%;
      overflow: hidden;
    }

    .name {
      margin-top: 20px;
      color: #014E9E;
      text-align: center;
      font-family: 'FrutigerLTStdCn';
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }

    .description {
      margin-top: 20px;
      text-align: center;
    }
  }

  @media (min-width: 840px) {
    & > * {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .image {
        width: 25%;
        margin-left: 0;
      }

      .text {
        width: 70%
      }

      .name {
        margin-top: 0;
        text-align: left;
      }

      .description {
        text-align: left;
      }
    }
  }
}

</style>
