<template>
  <div>
    <a-box class="article">
      <div class="image" v-if="data.image">
        <a-image :data="data.image" thumbnail="header" :alt="data.title" />
      </div>

      <div class="main">
        <div class="attributes">
          <div v-if="audioIds.length > 0" class="audio-icon icon-headphones"></div>
          <div v-if="data.youtube_video_id" class="video-icon icon-play"></div>
        </div>

        <div class="title">
          <h1 v-html="data.title"></h1>
        </div>

        <div class="teaser" v-html="data.teaser"></div>

        <div class="index" v-if="index.length">
          <h2>Inhaltsverzeichnis</h2>
          <ul>
            <li v-for="(headline, index) in index" :key="index">
              <a @click="scrollTo(headline)">{{ headline.innerHTML }}</a>
            </li>
          </ul>
        </div>

        <div class="share-top">
          <a-share :title="data.title" />
        </div>

        <div class="content" v-if="!data.video_above_content" v-html="data.content"></div>

        <div class="video" v-if="data.youtube_video_id">
          <a-youtube-video :id="data.youtube_video_id" />
        </div>

        <div class="content" v-if="data.video_above_content" v-html="data.content"></div>

        <div class="audio" v-if="audioIds.length > 0">
          <a-audio-list :ids="audioIds" />
        </div>

        <div class="experts" v-if="expertIds.length > 0">
          <a-expert-list :ids="expertIds" />
        </div>

        <div class="downloads" v-if="downloadIds.length > 0">
          <h2>Downloads</h2>
          <a-download-list :ids="downloadIds" />
        </div>

        <div class="image-description" v-if="data.image" v-html="data.image.description"></div>

        <!--
        <div class="share-bottom">
          <span>Hat Ihnen der Beitrag gefallen? Erzählen Sie einem Freund davon!</span>
          <a-share :title="data.title" />
        </div>
        -->
      </div>
    </a-box>
    <a-box v-if="relatedIds">
      <div class="related-articles">
        <a-article-list compact title="Diese Artikel könnten Dich auch interessieren" :ids="relatedIds" />
      </div>
    </a-box>
  </div>
</template>

<script>

import ContentService from '../../services/content'

const defaults = {
  index: []
}

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => Object.assign({}, defaults),
  computed: {
    downloadIds () {
      return this.data.downloads.map(relation => relation.download_id)
    },
    expertIds () {
      return this.data.experts.map(relation => relation.expert_id)
    },
    audioIds () {
      return this.data.audio.map(relation => relation.file_id)
    },
    relatedIds () {
      return this.data.related_articles.map(relation => relation.related_article_id)
    }
  },
  watch: {
    data: {
      immediate: true,
      handler (to) {
        this.reset()

        setTimeout(() => {
          if (to.generate_index) {
            this.index = document.querySelectorAll('.article .main .content h2')
          }

          const images = []
          const imageDescription = document.querySelector('.article .main .image-description')

          document.querySelectorAll('.article .main .content img').forEach(image => {
            images.push(image.src.split('/').pop())
          })

          ContentService.getFiles('fields=description&filter[description][nempty]&filter[filename_disk][in]=' + images.join(',')).then(result => {
            result.data.forEach(image => {
              imageDescription.innerHTML = imageDescription.innerHTML + image.description
            })
          })
        }, 200)
      }
    }
  },
  methods: {
    scrollTo (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    },
    reset () {
      for (const key in defaults) {
        this[key] = defaults[key]
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.article {
  .image {
    position: relative;
    z-index: 1;

    &::v-deep {
      img {
        display: block;
        width: 100%;
      }
    }

    @media (min-width: 840px) {
      min-height: 500px;
      background-color: #eee;
    }
  }

  .main {
    position: relative;
    z-index: 2;
    padding: 50px 0 0 0;
    text-align: left;

    .title {
    }

    .teaser {
      margin-top: 20px;
    }

    .index {
      margin-top: 20px;
    }

    .share-top {
      margin-top: 40px;
      padding-bottom: 20px;
      border-bottom: 1px solid #C0E1FF;
    }

    .share-bottom {
      margin-top: 40px;
      padding: 30px 0 20px 0;
      border-top: 1px solid #C0E1FF;

      span {
        display: block;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 20px;
      }
    }

    .content {
      margin-top: 30px;

      &::v-deep {
        .float-left,
        .float-right {
          img {
            display: block;
            margin: 20px auto;

            @media (min-width: 840px) {
              margin: 0;
            }
          }
        }
      }
    }

    .attributes {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;

      .audio-icon,
      .video-icon {
        background-color: #014E9E;
        color: #fff;
        margin-left: 3px;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        font-weight: normal;
      }
    }

    .video {
      margin-top: 30px;
    }

    .experts {
      margin-top: 30px;
      border: 1px solid #C0E1FF;
      border-left: 0;
      border-right: 0;
      padding: 30px 0;
    }

    .author + .share-bottom {
      border-top: none;
      margin-top: 0;
    }

    .downloads {
      margin-top: 50px;
    }

    .image-description {
      margin-top: 40px;
      padding: 30px 0 20px 0;
      border-top: 1px solid #C0E1FF;
      font-size: 14px;
    }

    @media (min-width: 840px) {
      margin: -70px auto 0 auto;
      background-color: #fff;
      width: 800px;
      padding: 70px 50px 0 50px;

      .teaser {
        margin-top: 30px;
      }

      .index {
        margin-top: 30px;
      }

      .attributes {
        right: 5px;
        top: 5px;

        .audio-icon,
        .video-icon {
          margin-left: 5px;
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 18px;
        }
      }

      .share-top {
        position: absolute;
        left: -10px;
        top: 200px;
        border-bottom: 0;
        padding: 0;

        .share {
          flex-direction: column;

          &::v-deep {
            .service {
              margin: 0 0 20px 0;
            }
          }
        }
      }

      .share-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          margin: 0;
        }
      }
    }
  }
}

.related-articles {
  padding-top: 30px;

  &::v-deep {
    h1 {
      text-align: left;
    }
  }

  @media (min-width: 840px) {
      margin: 0 auto;
      width: 800px;
      padding: 0 50px;
  }
}

</style>
