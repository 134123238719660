export default {
  isWeb: true,
  isIos: false,
  isAndroid: false,
  platform: 'web',
  mode: 'web',
  system: {
    appCodeName: navigator.appCodeName,
    appName: navigator.appName,
    appVersion: navigator.appVersion,
    cookieEnabled: navigator.cookieEnabled,
    language: navigator.language,
    onLine: navigator.onLine,
    platform: navigator.platform,
    userAgent: navigator.userAgent
  },
  locale: navigator.language,
  width: window.innerWidth,
  height: window.innerHeight,
  scale: 1,
  offsetTop: 0,
  offsetBottom: 0,
  basePathCss: ''
}
