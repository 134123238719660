<template>
  <div :class="classes" @click="check">
    <a-icon :color="color" :type="icon" size="24" />
    <div class="text">
      <div v-if="label.length > 0" class="label">{{ label }}</div>
      <div v-if="description.length > 0" class="description">{{ description }}</div>
    </div>
  </div>
</template>

<script>

import { colors } from '@/helpers/ui'

export default {
  model: {
    prop: 'checked',
    event: 'check'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    value: {},
    checked: {},
    disabled: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    radio: {}
  },
  computed: {
    classes () {
      const classes = ['checkbox', 'control', 'type-' + this.type]

      if (this.isChecked) classes.push('checked')
      if (this.disabled) classes.push('disabled')
      if (this.bold) classes.push('bold')
      if (this.description.length > 0) classes.push('has-description')

      return classes.join(' ')
    },
    icon () {
      if (this.isChecked) {
        return this.type === 'radio' ? 'fas:dotCircle' : 'fas:checkSquare'
      } else {
        return this.type === 'radio' ? 'far:circle' : 'far:square'
      }
    },
    color () {
      return this.isChecked && !this.disabled ? colors.primary : colors.grey
    },
    type () {
      return this.radio === 'true' || this.radio === '' || this.radio === true ? 'radio' : 'default'
    },
    isChecked () {
      if (this.type === 'radio') {
        return this.checked === this.realValue
      } else if (this.checked instanceof Array) {
        return this.checked.indexOf(this.realValue) >= 0
      } else {
        return this.checked
      }
    },
    realValue () {
      return typeof this.value === 'undefined' ? this.label : this.value
    }
  },
  methods: {
    check () {
      if (!this.disabled) {
        if (this.type === 'radio') {
          this.$emit('check', this.realValue)
        } else if (this.checked instanceof Array) {
          const array = this.checked.slice()

          if (this.isChecked) {
            const index = this.checked.indexOf(this.realValue)

            if (index >= 0) {
              array.splice(index, 1)
            }
          } else {
            array.push(this.realValue)
          }

          this.$emit('check', array)
        } else {
          this.$emit('check', !this.isChecked)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.checkbox {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: flex;

  &.disabled {
    cursor: default;
  }

  .label {
    color: #3b3b3b;
  }

  &.bold .label {
    font-weight: 900;
  }

  &.has-description .label {
    font-size: 20px;
  }

  .text {
    margin-left: 5px;

    .description {
      margin-top: 10px;
    }
  }
}

</style>
