<template>
  <div :class="classes">
    <div class="wrapper" @click="wrapperAction">
      <template v-if="type === 'text' || type === 'number'">
        <div class="input">
          <input ref="input" @focus="onFocus" @blur="onBlur" v-model="valueReal" :keyboardType="keyboardTypeReal" :secure="secure && !visibility" :maxLength="parseInt(maxLength)" :editable="!readonly" />
        </div>
        <!--
        <AgIcon col="1" v-if="info" type="info" @click="onInfo" />
        <AgIcon :col="info ? 2 : 1" v-if="secure" :type="visibility ? 'eye' : 'eyeSlash'" @click="toggleVisibility" />
        <AgIcon :col="info ? 2 : 1" v-if="readonly" type="ban" color="#C6C6C6" />
        -->
      </template>
      <template v-if="type === 'date'">
        <div col="0" class="input" :text="dateText" />
        <!--
        <AgIcon col="1" v-if="info" type="info" @click="onInfo" />
        <AgIcon :col="info ? 2 : 1" type="far:calendar" />
        -->
      </template>
      <template v-if="type === 'select'">
        <div class="input">
          <select ref="input" @focus="onFocus" @blur="onBlur" v-model="valueReal">
            <option v-for="(option, i) in options" :key="i" :selected="option.value === value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
        <!--
        <AgIcon col="1" v-if="info" type="info" @click="onInfo" />
        <AgIcon :col="info ? 2 : 1" type="bars" />
        -->
      </template>
      <template v-if="type === 'multiline'">
        <div class="input">
          <textarea col="0" ref="input" v-model="valueReal" @focus="onFocus" @blur="onBlur"></textarea>
        </div>
        <!--
        <AgIcon col="1" v-if="info" type="info" @click="onInfo" />
        -->
      </template>
    </div>
    <div ref="label" class="label" left="10" top="30" orientation="horizontal" @click="labelAction">
      {{ label }}
      <span v-if="required" class="required-flag">*</span>
    </div>
    <div v-if="invalid" class="error">
      <!--
      <AgIcon col="0" type="exclamationCircle" :color="errorColor" size="16" />
      -->
      <div class="text">{{ error }}</div>
    </div>
  </div>
</template>

<script>

import locale from '../locales'

export default {
  props: {
    label: {
      type: String,
      default: '[label]'
    },
    value: {
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    keyboardType: {
      type: String,
      default: ''
    },
    secure: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: [Number, String],
      default: 9999 // IT'S OVER 9000!!!!!
    },
    readonly: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default () {
        return []
      }
    },
    rules: {
      type: Array,
      default () {
        return []
      }
    },
    dark: {
      default: false,
      type: Boolean
    }
  },
  data () {
    let valueReal = this.value

    if (this.type === 'date' && !valueReal.length) {
      const date = new Date()

      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)

      valueReal = date.toISOString()
    }

    return {
      valueReal,
      valueSelected: null,
      valueText: '',
      visibility: false,
      focused: false,
      filled: false,
      touched: false,
      error: false,
      errorColor: 'red'
    }
  },
  computed: {
    classes () {
      const classes = ['control', 'input-control', 'type-' + this.type]

      if (this.dark) classes.push('dark')
      if (this.filled) classes.push('filled')
      if (this.focused) classes.push('focused')
      if (this.invalid) classes.push('invalid')
      if (this.readonly) classes.push('readonly')

      return classes.join(' ')
    },
    columns () {
      let columns = '*'

      if (this.type === 'date' || this.type === 'select' || (this.type === 'text' && (this.secure || this.readonly))) {
        columns += ', 60'
      }

      if (this.info) {
        columns += ', 60'
      }

      return columns
    },
    dateText () {
      return locale.formatDateMedium(this.valueReal)
    },
    selectionText () {
      return this.selectionIndex >= 0 && this.options[this.selectionIndex].label ? this.options[this.selectionIndex].label : this.valueReal
    },
    selectionIndex () {
      return this.options.findIndex(item => item.value === this.valueReal)
    },
    keyboardTypeReal () {
      return this.type === 'number' ? 'number' : this.keyboardType
    },
    invalid () {
      if (!this.touched) {
        return false
      }

      return !this.validate()
    }
  },
  watch: {
    valueReal: {
      immediate: true,
      handler (to) {
        this.filled = to !== null && String(to).length > 0
        this.$emit('input', to)
      }
    },
    value: {
      immediate: true,
      handler (to) {
        this.valueReal = to
      }
    }
  },
  methods: {
    labelAction () {
      if (this.readonly) {
        return false
      }

      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    },
    wrapperAction () {
      this.labelAction()
    },
    onFocus () {
      if (!this.readonly) {
        this.focused = true
        this.$emit('focus')
      }
    },
    onBlur () {
      this.focused = false
      this.touched = true
      this.$emit('blur')
    },
    toggleVisibility () {
      this.visibility = !this.visibility
    },
    onInfo (event) {
      this.$emit('info', event)
    },
    pickDate () {
      this.focused = true

      /*
      dialog.component(this, AgModalDate, {
        label: this.label,
        value: this.valueReal
      }).then(result => {
        this.focused = false

        if (result) {
          this.valueReal = result.toISOString()
        }
      })
      */
    },
    validate () {
      for (let i = 0; i < this.rules.length; ++i) {
        const result = this.rules[i](this.valueReal)

        if (result !== true) {
          this.error = result

          return false
        }
      }

      return true
    },
    validateByForm () {
      this.touched = true

      return !this.invalid
    }
  }
}

</script>

<style scoped lang="scss">

.input-control {
  position: relative;

  .wrapper {
    border-style: solid;
    border-color: #C6C6C6;
    background-color: #FFFFFF;
    width: 100%;
    border-width: 1px;
    min-height: 60px;
    border-radius: 6px;
    overflow: hidden;

    .input {
      padding: 17px 0 0 0;

      input,
      select,
      textarea {
        display: block;
        width: 100%;
        border: none;
        min-height: 40px;
        background-color: transparent;
        font-family: 'Syntax LT';
        font-size: 16px;
        line-height: 26px;
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 7px 16px;
        margin: 0;
      }
    }
  }

  &.focused .wrapper {
    border-color: #004e9e;
  }

  &.invalid .wrapper {
    border-color: #e84c3d;
  }

  &.dark .wrapper,
  &.dark.focused .wrapper {
    border-color: transparent;
  }

  .label {
    font-family: 'FrutigerLTStdCn';
    font-weight: normal;
    color: #b3b3b3;
    background-color: #fff;
    transform-origin: 0 50%;
    position: absolute;
    top: 17px;
    left: 9px;
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    border-radius: 12px;
    padding: 0 7px;
    transform: translate(0, 0) scale(1, 1);
    transition: transform 200ms;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .required-flag {
      color: #e84c3d;
    }
  }

  &.focused .label,
  &.filled .label,
  &.type-select .label {
    transform: translate(2px, -12px) scale(0.8, 0.8);
  }

  &.filled .label,
  &.filled.readonly .label {
    color: #000000;
  }

  &.focused .label {
    color: #004e9e;
  }

  &.filled:not(.focused) .label {
    transition: transform 0ms;
  }

  &.invalid.focused .label,
  &.invalid.filled.focused .label {
    color: #e84c3d;
  }

  &.type-multiline .wrapper {

  }

  &.type-text,
  &.type-multiline {
    cursor: text;
  }

  &.type-multiline .input textarea {
    height: 100px;
    resize: vertical;
  }

  &.visibility {
    background-color: silver;
    color: #004e9e;
  }

  .error {
    color: #e84c3d;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
  }

  .error .text {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

</style>
