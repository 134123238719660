<template>
  <a :href="url" :target="target" @click="handle">
    <slot name="default" />
  </a>
</template>

<script>

export default {
  props: {
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    }
  },
  computed: {
    isExternal () {
      return /^[a-z-]+:\/\//.test(this.href)
    },
    url () {
      return this.isExternal ? this.href : this.$router.resolve(this.href).href
    }
  },
  methods: {
    handle (event) {
      if (!this.target) {
        event.preventDefault()

        if (this.isExternal) {
          document.location.href = this.url
        } else {
          this.$router.push({
            path: this.href
          })
        }
      }

      this.$emit('click', event)
    }
  }
}

</script>
