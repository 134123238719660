<template>
  <div class="files">
    <div v-for="file in files" :key="file.id">
      <div class="title">
        <h2>{{ file.title }}</h2>
      </div>

      <div class="description" v-if="file.description" v-html="file.description"></div>
      <div class="controls">
        <audio controls>
          <source :src="file.data.full_url" :type="file.type">
        </audio>
      </div>
    </div>
  </div>
</template>

<script>

import ContentService from '../services/content'

export default {
  props: {
    ids: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    files: []
  }),
  watch: {
    ids: {
      immediate: true,
      handler (to) {
        ContentService.getFiles('filter[id][in]=' + to.join(',')).then(result => {
          this.files = result.data
        })
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.files {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > * {
    margin-top: 30px;

    .description {
      margin-top: 15px;
    }

    .controls {
      margin-top: 15px;
    }
  }
}

</style>
