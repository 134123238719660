<template>
  <div class="instagram-feed" v-if="show">
    <div class="title">{{ config.title }}</div>
    <div class="text" v-html="config.text"></div>
    <div class="link">
      <a :href="config.link_url" target="_blank">
        <span class="icon-chevron-right"></span>
        <span>{{ config.link_text }}</span>
      </a>
    </div>
    <div class="consent-hint" v-if="!allowed">An dieser Stelle findest Du eigentlich unsere letzten Instagram Posts, die aufgrund Deiner Cookie-Einstellungen ausgeblendet werden. Du kannst die Posts über <a :href="config.profile_url" target="_blank">diesen Link</a> außerhalb unserer Webseite aufrufen oder einfach <a @click="openCookieSettings">hier</a> Deine Cookie-Einstellungen anpassen.</div>
    <div class="images" id="instafeed" v-else></div>
    <div class="profile">
      <a :href="config.profile_url" target="_blank">
        <span class="icon-instagram"></span>
        <span>{{ config.profile_name }}</span>
      </a>
    </div>
  </div>
</template>

<script>

import consent from '../helpers/consent'
import ContentService from '../services/content'
import Instafeed from '../external/instafeed.min.js'

export default {
  props: {
    limit: {
      type: [String, Number]
    }
  },
  data: () => ({
    config: {},
    allowed: false
  }),
  computed: {
    show () {
      return this.allowed && Object.keys(this.config).length && this.config.status
    }
  },
  watch: {
    show: {
      immediate: true,
      handler (to) {
        if (to) {
          const tags = this.config.tags.length ? this.config.tags.toLowerCase().split(' ') : []

          // sollte auf Dauer aus dem FG geladen werden
          var feed = new Instafeed({
            accessToken: this.config.token,
            template: '<div class="type-{{type}}"><a href="{{link}}" title="{{caption}}" target="_blank" style="background-image: url({{image}});" /></a></div>',
            limit: parseInt(this.limit),
            filter: image => {
              if (tags.length) {
                return image.tags.find(tag => tags.indexOf(tag.toLowerCase()) >= 0)
              } else {
                return true
              }
            },
            after: () => {
              setTimeout(() => {
                this.setHeight()
              }, 200)
            }
          })

          setTimeout(() => {
            feed.run()
          }, 100)
        }
      }
    }
  },
  methods: {
    setHeight () {
      const elements = document.querySelectorAll('.instagram-feed .images div')

      if (elements.length) {
        const height = elements[0].offsetWidth + 'px'

        elements.forEach(element => {
          element.style.height = height
        })
      }
    },
    openCookieSettings () {
      consent.showScreen()
    }
  },
  mounted () {
    consent.onConsent('Instagram', to => {
      this.allowed = to
    }, true)

    ContentService.getItems('instagram').then(result => {
      this.config = result.data[0]
    })

    window.addEventListener('resize', () => {
      this.setHeight()
    })
  }
}

</script>

<style lang="scss" scoped>

.instagram-feed {
  color: #fff;
  background-color: #014E9E;
  position: relative;
  padding-bottom: 110px;

  .title {
    font-family: 'Gloria Hallelujah';
    font-size: 30px;
    line-height: 50px;
    padding: 10px;
  }

  .text {
    margin-top: 5px;
    padding: 0 10px;
  }

  .link {
    padding: 20px 10px;

    a {
      color: #fff;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        font-weight: bold;
        font-size: 14px;
      }

      :first-child {
        margin-right: 7px;
        font-size: 10px;
        font-weight: normal;
      }
    }
  }

  .consent-hint {
    font-size: 14px;
    padding: 50px;

    &::v-deep {
      div {
        display: none;
      }

      a {
        color: #C0E1FF;
      }
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;

    &::v-deep {
      div {
        width: 50%;
        padding: 10px 0 0 0;

        &:nth-child(odd) {
          padding-right: 5px;
        }

        &:nth-child(even) {
          padding-left: 5px;
        }

        a {
          display: block;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-color: #444;
          position: relative;
        }

        &.type-video {
          a::after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 70px;
            height: 70px;
            margin: -35px 0 0 -35px;
            text-align: center;
            line-height: 70px;
            color: #fff;
            content: '\e90f';
            font-family: 'AGILA-HC';
            font-size: 60px;
            font-weight: normal;
          }
        }
      }
    }
  }

  .profile {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 40px;

    a {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-weight: bold;
      }

      :first-child {
        margin-right: 10px;
        font-size: 22px;
        font-weight: normal;
      }
    }
  }
}

</style>
