<template>
  <a-box>
    <div class="image" v-if="data.image">
      <a-image :data="data.image" thumbnail="header" :alt="data.title" />
    </div>
    <div class="main">
      <a-text v-html="data.content"></a-text>
      <div class="image-description" v-if="data.image" v-html="data.image.description"></div>
      <div class="bottom">
        <slot name="bottom"></slot>
      </div>
    </div>
  </a-box>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    setImageInfo (image) {
      this.imageDescription = image.description
    }
  }
}

</script>

<style lang="scss" scoped>

.image {
  position: relative;
  z-index: 1;

  img {
    display: block;
  }
}

.main {
  position: relative;
  z-index: 2;
  padding: 50px 0 0 0;
  text-align: left;
}

.image-description {
  margin-top: 40px;
  padding: 30px 0 20px 0;
  border-top: 1px solid #C0E1FF;
  font-size: 14px;
}

.bottom {
  margin: 20px 0 0 0;

  &:empty {
    display: none;
  }
}

@media (min-width: 840px) {
  .main {
    margin: -70px auto 0 auto;
    background-color: #fff;
    width: 800px;
    padding: 70px 50px 0 50px;
  }
}

</style>
