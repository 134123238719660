<template>
  <div :class="classes">
    <slot name="default" />
  </div>
</template>

<script>

export default {
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      const classes = ['actions']

      return classes
    }
  }
}

</script>

<style lang="scss" scoped>

.actions {
  margin-top: 10px;
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 5px;
    flex-grow: 1;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: 840px) {
    margin-top: 20px;
    justify-content: flex-start;

    & > * {
      margin: 0 10px;
      flex-grow: unset;
    }
  }
}

</style>
