<template>
  <div>
    <a-image-header :tags="data.content_list ? data.content_list.tags : ''">
      <a-text v-html="data.content"></a-text>
      <a-form class="form" ref="formTop">
        <div class="row">
          <a-input required label="E-Mail-Adresse" v-model="email" :rules="[rules.notEmpty, rules.email]" />
          <a-btn primary @click="submitTop">Weiter</a-btn>
        </div>
      </a-form>
      <!--
      <a-text small>Hier zum Newsletter anmelden und vorzeitig Informationen erhalten.</a-text>
      -->
    </a-image-header>
    <a-box center>
      <a-headline>Euch erwarten spannende Formate</a-headline>
      <a-text>Zum Welttierschutztag am 4. Oktober starten wir in diesem Jahr das erste Mal unsere Themenwochen unter dem Motto „Heute für morgen: Vorsorgen mit AGILA“. Bis Ende Oktober erwarten Euch hier regelmäßig neue Informationen rund um Themen wie Tierarztbesuche, Ernährung, Bewegung, Vorsorge zu hause und vieles mehr. Unter anderem haben wir folgende spannende Formate für Euch:</a-text>
      <div class="boxes">
        <div>
          <div class="image"><img src="../../assets/interview.svg" /></div>
          <a-headline sub>Experteninterviews</a-headline>
          <a-text>Wir haben mit Tierärzten zur Gesundheitsvorsorge bei Hunde und Katzen gesprochen und auch eine Reportage zum Umgang mit diesem Thema in Tierheimen entwickelt. Alle wichtigen Fakten findet Ihr bald hier!</a-text>
        </div>
        <div>
          <div class="image"><img src="../../assets/video.svg" /></div>
          <a-headline sub>Videos</a-headline>
          <a-text>In verschiedenen Videos nehmen wir Euch z.B. mit auf „Eine Runde Gassi mit…“ einer prominenten Hundebesitzerin und lassen Tierärzte zu Themen der Gesundheitsvorsorge zu Wort kommen.</a-text>
        </div>
        <div>
          <div class="image"><img src="../../assets/podcast.svg" /></div>
          <a-headline sub>Podcast</a-headline>
          <a-text>Ihr stellt die Fragen, unsere Tierärztin antwortet! Auf unseren Social Media Kanälen könnt Ihr im Rahmen der Aktion Eure offenen Fragen zur Gesundheitsvorsorge stellen, die Tierärztin Melanie Ahlers in einem Podcast beantwortet.</a-text>
        </div>
        <div>
          <div class="image"><img src="../../assets/contest.svg" /></div>
          <a-headline sub>Gewinnspiel</a-headline>
          <a-text>Haltet die Augen offen, denn natürlich haben wir uns für Euch auch ein passendes Gewinnspiel rund um die Gesundheitsvorsorge überlegt. Folgt schon jetzt unseren Social Media Kanälen, um es nicht zu verpassen!</a-text>
        </div>
      </div>
    </a-box>
    <div class="wrapper blue">
      <a-box>
        <a-headline>Ihr seid bereit für Infos rund um tierische Gesundheitsvorsorge?<br>Dann meldet Euch jetzt für unseren Newsletter an!</a-headline>
        <a-form class="form" ref="formBottom">
          <div class="row">
            <a-input required dark label="E-Mail-Adresse" v-model="email" :rules="[rules.notEmpty, rules.email]" />
            <a-btn primary dark @click="submitBottom">Weiter</a-btn>
          </div>
        </a-form>
        <a-text small>Hier zum Newsletter anmelden und vorzeitig Informationen erhalten.</a-text>
      </a-box>
      <div class="icon-paw"></div>
      <div class="icon-paw"></div>
    </div>
    <a-box center class="spaced">
      <a-headline sub>Die AGILA Haustierversicherung aus Hannover.</a-headline>
      <a-text>Wir sind Euer Partner beim Thema Hunde-und Katzenversicherung. Leistungsstark, fair und günstig.</a-text>
    </a-box>
  </div>
</template>

<script>

import validation from '../../helpers/validation'
import ImageHeader from '@/components/ImageHeader'

export default {
  components: {
    'a-image-header': ImageHeader
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    email: '',
    rules: {
      notEmpty: (v) => validation.notEmpty(v) || 'Darf nicht leer sein!',
      email: (v) => validation.email(v) || 'Entspricht nicht einer E-Mail-Adresse!'
    }
  }),
  methods: {
    submitTop () {
      if (this.$refs.formTop.validate()) {
        this.submit()
      }
    },
    submitBottom () {
      if (this.$refs.formBottom.validate()) {
        this.submit()
      }
    },
    submit () {
      this.$router.push({
        path: '/newsletter-anmeldung',
        query: {
          email: this.email
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>

.boxes {
  & > * {
    margin-top: 40px;
  }

  .image {
    margin-bottom: 10px;

    img {
      height: 60px;
    }
  }

  @media (min-width: 840px) {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    margin: 60px auto 0 auto;

    & > * {
      margin-top: 0;
      text-align: left;
      width: 50%;

      &:nth-child(even) {
        padding-left: 30px;
      }

      &:nth-child(odd) {
        padding-right: 30px;
      }

      &:nth-child(1) {
        padding-bottom: 200px
      }

      &:nth-child(2) {
        padding-top: 130px
      }

      &:nth-child(3) {
        margin-top: -120px
      }
    }

    .image {
      margin-bottom: 15px;

      img {
        height: 70px;
      }
    }
  }
}

.wrapper {
  position: relative;
  z-index: 2;
  margin-top: 50px;

  &:first-child {
    margin-top: 0;
  }

  &.blue {
    background-color: #014E9E;
    padding-top: 60px;
    padding-bottom: 80px;
    position: relative;
    overflow: hidden;

    .headline {
      color: #fff;
    }

    .text {
      color: #fff;
    }

    .form {
      margin-top: 40px;
      min-height: 120px;
    }

    @media (min-width: 840px) {
      .box {
        display: flex;
        align-items: center;

        & > * {
          width: 50%;
        }

        .headline {
          font-size: 30px;
          line-height: 36px;
          padding-right: 30px;
          margin: 0;
        }

        .form {
          padding-left: 30px;
        }

        .text {
          display: none;
        }
      }
    }
  }

  .box {
    position: relative;
    z-index: 2;
  }

  .icon-paw {
    position: absolute;
    z-index: 1;
    left: 50%;
    margin: 0 0 0 120px;
    top: 290px;
    color: #fff;
    font-size: 120px;
    opacity: 0.2;
  }

  .icon-paw + .icon-paw {
    transform: scale(-1, 1);
    font-size: 200px;
    top: 150px;
    left: auto;
    right: 50%;
    margin: 0 90px 0 0;
  }

  @media (min-width: 840px) {
    .icon-paw {
      top: 160px;
      margin-left: 400px;
      font-size: 200px;
    }

    .icon-paw + .icon-paw {
      top: -20px;
      right: auto;
      left: 50%;
      font-size: 340px;
      margin: 0 0 0 50px;
    }
  }
}

.box.spaced {
  text-align: center;
}

</style>
