<template>
  <div class="wrapper">
    <a-loader v-if="loading" />
    <a-box v-else>
      <a-headline>Seite nicht gefunden</a-headline>
      <a-text>Irgendwas scheint mit der Seite passiert zu sein. Wir konnten sie leider nirgendwo finden.</a-text>
      <a-actions>
        <a-btn primary @click="goToHome">Zur Startseite</a-btn>
      </a-actions>
    </a-box>
  </div>
</template>

<script>

export default {
  methods: {
    goToHome () {
      this.$router.push({
        path: '/'
      })
    }
  }
}

</script>

<style lang="scss" scoped>

.wrapper {
  background-color: #F6FAFF;
}

</style>
