<template>
  <div class="form">
    <slot name="default" />
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      validatables: []
    }
  },
  methods: {
    findValidatables (children) {
      children.forEach(child => {
        this.findValidatables(child.$children)

        if (typeof child.validateByForm === 'function') {
          this.validatables.push(child)
        }
      })
    },
    validate () {
      let valid = true

      this.findValidatables(this.$children)

      for (let i = 0; i < this.validatables.length; ++i) {
        if (!this.validatables[i].validateByForm()) {
          valid = false
        }
      }

      return valid
    }
  }
}

</script>

<style lang="scss" scoped>

.form {
  margin: 30px 0 10px 0;

  &::v-deep {
    .row {
      display: flex;
      margin-bottom: 10px;

      & > * {
        margin: 0 5px;
        flex-grow: 1;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media (min-width: 840px) {
      .row {
        margin-bottom: 20px;

        & > * {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }

  @media (min-width: 840px) {
    margin-bottom: 20px;
  }
}

</style>
