<template>
  <div class="image-header">
    <a-box class="img">
      <slot name="default" />
    </a-box>
    <div class="tags" v-html="tags"></div>
  </div>
</template>

<script>

export default {
  props: {
    tags: {
      type: String
    }
  }
}

</script>

<style lang="scss" scoped>

.image-header {
  position: relative;
  background-image: url(~@/assets/xs-agila-online-mag-bg-img.jpg);
  background-color: #eee;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  min-height: 760px;

  &::v-deep .text * {
    color: #fff;
  }

  @media (min-width: 540px) {
    min-height: 1000px;
  }

  @media (min-width: 660px) {
    min-height: 1100px;
  }

  @media (min-width: 780px) {
    min-height: 1200px;
  }

  @media (min-width: 840px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1380px;
    min-height: 467px;
    background-image: url(~@/assets/xl-agila-online-mag-bg-img.jpg);
    background-size: 100% auto;

    .box {
      padding-right: 360px;
      padding-top: 10px;
    }
  }

  @media (min-width: 900px) {
    min-height: 500px;

    .box {
      padding-right: 420px;
      padding-top: 30px;
    }
  }

  @media (min-width: 960px) {
    min-height: 534px;

    .box {
      padding-right: 460px;
      padding-top: 40px;
    }
  }

  @media (min-width: 1020px) {
    min-height: 568px;

    .box {
      padding-right: 480px;
      padding-top: 50px;
    }
  }

  @media (min-width: 1080px) {
    min-height: 600px;

    .box {
      padding-right: 500px;
      padding-top: 60px;

      &::v-deep h2 {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }

  @media (min-width: 1140px) {
    min-height: 636px;

    .box {
      padding-right: 520px;
      padding-top: 70px;
    }
  }

  @media (min-width: 1200px) {
    min-height: 668px;

    .box {
      padding-right: 540px;
      padding-top: 80px;
    }
  }

  @media (min-width: 1260px) {
    min-height: 702px;

    .box {
      padding-right: 560px;
      padding-top: 90px;
    }
  }

  @media (min-width: 1320px) {
    min-height: 736px;

    .box {
      padding-right: 580px;
      padding-top: 100px;
    }
  }

  @media (min-width: 1380px) {
    min-height: 770px;

    .box {
      padding-right: 600px;
      padding-top: 110px;
    }
  }

  .tags {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(~@/assets/xs-agila-online-mag-line-element.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 70px 20px 0 0;
    height: 130px;
    text-align: center;
    color: #fff;
    font-family: 'Gloria Hallelujah';
    font-size: 15px;

    @media (min-width: 420px) {
      font-size: 16px;
      height: 160px;
      padding-top: 90px;
    }

    @media (min-width: 540px) {
      font-size: 17px;
      height: 190px;
      padding-top: 110px;
    }

    @media (min-width: 660px) {
      font-size: 18px;
      height: 230px;
      padding-top: 150px;
    }

    @media (min-width: 840px) {
      background-image: url(~@/assets/xl-agila-online-mag-line-element.png);
      text-align: center;
      font-size: 19px;
      height: 150px;
      padding-top: 100px;
    }

    @media (min-width: 960px) {
      font-size: 20px;
      height: 170px;
      padding-top: 120px;
    }

    @media (min-width: 1080px) {
      font-size: 21px;
      height: 200px;
      padding-top: 140px;
    }

    @media (min-width: 1200px) {
      font-size: 22px;
      height: 220px;
      padding-top: 150px;
    }
  }
}

</style>
