<template>
  <a-box class="survey-wrapper">
    <div class="image" v-if="data.image">
      <a-image :data="data.image" thumbnail="header" />
    </div>
    <div class="main">
      <div class="title">
        <h1>{{ data.title }}</h1>
      </div>
      <template v-if="state === 'initial' && question">
        <div class="text">
          <a-text v-html="data.content"></a-text>
        </div>
        <div class="survey">
          <div class="question">
            <div>Frage {{ current + 1 }} / {{ questions.length }}</div>
            <p>
              <b v-html="question.text"></b>
            </p>
          </div>
          <div class="answer" v-if="question.answer_type === 'text'">
            <a-input label="Antwort" type="multiline" v-model="textAnswer" />
          </div>
          <div class="answers" v-else-if="question.answer_type === 'selection'">
            <a v-for="([answer, score], index) in Object.entries(question.answers)" :key="index" @click="select(answer, score)">{{ answer }}</a>
          </div>
          <div :class="'buttons' + (current ? '' : ' start')" v-if="current || question.answer_type !== 'selection'">
            <a-btn @click="back" v-if="current">Zurück</a-btn>
            <a-btn primary @click="next" v-if="question.answer_type !== 'selection'">Weiter</a-btn>
          </div>
        </div>
      </template>
      <template v-if="state === 'summary'">
        <div class="summary">
          <h2>Zusammenfassung</h2>
          <div class="question" v-for="(question, questionIndex) in questions" :key="question.id">
            <p class="text" v-html="question.text"></p>
            <p class="answer">{{ selection[questionIndex].answer }}</p>
          </div>
        </div>
        <div class="buttons">
          <a-btn @click="back" v-if="current">Zurück</a-btn>
          <a-btn primary @click="submit">Abschicken</a-btn>
        </div>
      </template>
      <template v-if="state === 'evaluation'">
        <div class="result">
          <h2>Ergebnis</h2>
          <div class="score" v-if="total && survey.score_type !== 'none'">
            <template v-if="survey.score_type === 'percentage'">
              {{ percentage }}%
            </template>
            <template v-if="survey.score_type === 'oftotal'">
              {{ score }} von {{ total }}
              <div>möglichen Punkten</div>
            </template>
          </div>
          <div class="enlightenment" v-if="survey.enlightenment" v-html="survey.enlightenment"></div>
          <div class="result-percentage" v-html="result.percentage + '%'"></div>
          <div class="result-text" v-html="result.text"></div>
        </div>
        <div class="evaluation-list" v-if="survey.action === 'list_evaluation'">
          <template v-for="(evaluation, index) in sortedEvaluation">
            <div v-if="evaluation !== result" :key="index" :class="evaluationOpen === index ? 'open' : ''">
              <div class="percentage" v-html="evaluation.percentage + '%'"></div>
              <div class="text" v-html="evaluation.text"></div>
              <a class="more" href="javascript:" @click="toggleEvaluation(index)">{{ evaluationOpen === index ? 'weniger' : 'mehr' }}</a>
            </div>
          </template>
        </div>
        <div class="solving" v-if="survey.action === 'show_solving'">
          <h2>Auflösung</h2>
          <div class="question" v-for="(question, questionIndex) in questions" :key="question.id">
            <p class="text" v-html="question.text"></p>
            <p class="answer" v-if="question.answer_type === 'text'">
              {{ selection[questionIndex].answer }}
            </p>
            <ul class="answers" v-else-if="question.answer_type === 'selection'">
              <li v-for="([answer, score], answerIndex) in Object.entries(question.answers)" :key="answerIndex" :class="(score === '0' ? 'wrong' : 'right') + (selection[questionIndex].answer === answer ? ' selected' : '')">
                {{ answer }}
              </li>
            </ul>
          </div>
        </div>
        <div class="buttons">
          <a-btn @click="reset">Zum Anfang</a-btn>
        </div>
      </template>
      <div class="image-description" v-if="data.image" v-html="data.image.description"></div>
      <div class="bottom">
        <slot name="bottom"></slot>
      </div>
    </div>
  </a-box>
</template>

<script>

import ContentService from '../../services/content'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    state: 'initial',
    current: 0,
    selection: [],
    survey: [],
    questions: [],
    result: null,
    score: 0,
    sortedEvaluation: [],
    evaluationOpen: null,
    textAnswer: ''
  }),
  computed: {
    question () {
      return this.questions[this.current] || null
    },
    total () {
      let total = 0

      if (this.questions.length) {
        this.questions.forEach(question => {
          if (question.answers) {
            total += Object.values(question.answers).reduce((sum, value) => Math.max(parseInt(sum), parseInt(value)))
          }
        })
      }

      return total
    },
    percentage () {
      return Math.round(this.score / this.total * 100)
    }
  },
  watch: {
    survey: {
      immediate: true,
      handler (to) {
        this.sortedEvaluation = to.evaluation instanceof Object ? Object.entries(this.survey.evaluation).map(([score, text]) => ({ score: parseInt(score), text })).sort((a, b) => b.score - a.score) : []
      }
    },
    current (to) {
      if (to >= this.questions.length) {
        this.state = 'summary'
      } else if (to < this.questions.length) {
        this.state = 'initial'

        if (typeof this.selection[to] === 'string') {
          this.textAnswer = this.selection[to].answer
        }
      }
    },
    selection (to) {
      this.score = 0

      to.forEach((answer, question) => {
        if (this.questions[question].answers) {
          this.score += parseInt(this.questions[question].answers[answer])
        }
      })
    },
    settings: {
      immediate: true,
      handler () {
        this.loadSurvey()
      }
    }
  },
  methods: {
    submit () {
      let result
      this.state = 'evaluation'

      for (const item of this.sortedEvaluation) {
        if (this.score <= item.score) {
          result = item
        }
      }

      this.result = result

      if (this.survey.action === 'transmit') {
        const data = []

        this.questions.forEach((question, index) => {
          var div = document.createElement('div')

          div.innerHTML = question.text

          data.push({
            q: div.innerText,
            a: this.selection[index].answer
          })
        })

        ContentService.postItems('survey_results', {
          survey: this.survey.title,
          data
        })
      } else if (this.survey.action === 'list_evaluation') {
        const max = this.questions.length

        this.sortedEvaluation.forEach(evaluation => {
          evaluation.percentage = this.selection.filter(s => s.score === evaluation.score).length / max * 100
        })

        const f = this.sortedEvaluation.sort((a, b) => b.percentage - a.percentage)

        this.result = f[0]
      }

      document.querySelector('.survey-wrapper .main').scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    },
    select (answer, score) {
      this.$set(this.selection, this.current, { answer, score: parseInt(score) })

      ++this.current
    },
    next () {
      this.$set(this.selection, this.current, { answer: this.textAnswer, score: 0 })
      this.textAnswer = ''

      ++this.current
    },
    back () {
      --this.current
    },
    loadSurvey () {
      if (this.settings.title) {
        ContentService.getItems('surveys', 'filter[title]=' + this.settings.title).then(resultSurveys => {
          this.survey = resultSurveys.data[0]

          ContentService.getItems('surveys_questions', 'fields=question_id.*&filter[survey_id]=' + this.survey.id).then(result => {
            this.questions = result.data.map(data => data.question_id).sort((a, b) => a.sort - b.sort)
          })
        })
      }
    },
    toggleEvaluation (index) {
      this.evaluationOpen = this.evaluationOpen === index ? null : index
    },
    reset () {
      this.state = 'initial'
      this.current = 0
      this.selection = []
      this.result = null
      this.score = 0
    }
  }
}

</script>

<style lang="scss" scoped>

.image {
  position: relative;
  z-index: 1;

  img {
    display: block;
  }
}

.main {
  position: relative;
  z-index: 2;
  padding: 50px 0 0 0;
  text-align: left;
}

.title {
}

.title + * {
  margin-top: 20px;
}

.image-description {
  margin-top: 40px;
  padding: 30px 0 20px 0;
  border-top: 1px solid #C0E1FF;
  font-size: 14px;
}

.survey {
  margin-top: 50px;

  .answer {
    margin-top: 20px;
  }

  .answers {
    & > * {
      display: block;
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #014E9E;
      color: #014E9E;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}

.bottom {
  margin: 20px 0 0 0;

  &:empty {
    display: none;
  }
}

.result {
  margin-top: 50px;

  .score {
    text-align: center;
    font-size: 30px;
    color: #014E9E;
    padding: 30px 0;

    div {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .result-percentage {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .enlightenment {
    border-bottom: 1px solid #c6c6c6;
    padding: 20px 0;
    margin-bottom: 20px;
  }
}

.evaluation-list {
  & > * {
    border-top: 1px solid #c6c6c6;
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;

    .percentage {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .text {
      height: 145px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.open .text {
      height: auto;
    }

    .more {
      margin-top: 20px;
      display: inline-block;
      font-weight: bold;
    }
  }
}

.summary,
.solving {
  margin-top: 50px;

  .question {
    padding: 20px 0;
    margin-top: 0;
    border-top: 1px solid #c6c6c6;

    .text {
      font-weight: bold;
    }

    .answers {
      padding-left: 0;

      li {
        margin-top: 10px;
        text-align: center;
        list-style: none;

        &.selected::after {
          content: 'Deine Wahl';
          color: #fff;
          padding: 0 5px;
          border-radius: 4px;
          font-size: 12px;
          display: inline-block;
          height: 20px;
          line-height: 20px;
        }

        &.right {
          color: #014E9E;

          &::before {
            content: '✓ ';
          }

          &.selected::after {
            background-color: #014E9E;
          }
        }

        &.wrong {
          color: #c6c6c6;

          &::before {
            content: '✗ ';
          }

          &.selected::after {
            background-color: #c6c6c6;
          }
        }
      }
    }
  }

  :not(.question) + .question {
    border-top: none;
  }
}

.buttons {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  &.start {
    justify-content: flex-end;
  }
}

@media (min-width: 840px) {
  .main {
    margin: -70px auto 0 auto;
    background-color: #fff;
    width: 800px;
    padding: 70px 50px 0 50px;

    .title + * {
      margin-top: 30px;
    }

    .question {
      .answers {
        li {
          text-align: left;
        }
      }
    }
  }
}

</style>
