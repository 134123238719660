import axios from 'axios'

const connection = axios.create({
  // baseURL: 'http://localhost/health-campaign-2020/proxy/'
  baseURL: process.env.VUE_APP_FULL_URL + process.env.VUE_APP_CONTENT_SUBPATH
})

function buildUrl (base, options) {
  if (typeof options === 'number') {
    return base + '/' + options
  }

  if (typeof options === 'string') {
    return base + '?' + options
  }

  return base
}

export default {
  getItems (collection, params) {
    const options = {
      params: typeof params === 'object' ? params : {}
    }

    return connection.get(buildUrl('items/' + collection, params), options).then(response => {
      return Promise.resolve(response.data)
    })
  },
  postItems (collection, data) {
    return connection.post('items/' + collection, data).then(response => {
      return Promise.resolve(response.data)
    })
  },
  getFiles (params) {
    const options = {
      params: typeof params === 'object' ? params : {}
    }

    return connection.get(buildUrl('files', params), options).then(response => {
      return Promise.resolve(response.data)
    })
  },
  getPublishedCategories (filter) {
    return this.getItems('categories', 'status=published' + (filter ? '&' + filter : '')).then(result => {
      return Promise.resolve(result)
    })
  },
  getPublishedPages (filter) {
    return this.getItems('pages', 'status=published&fields=*,layout.*,image.*&filter[environment][like]=' + process.env.NODE_ENV + (filter ? '&' + filter : '')).then(result => {
      return Promise.resolve(result)
    })
  },
  getPublishedArticles (filter) {
    return this.getItems('articles', 'status=published&fields=*,image.*,downloads.*,experts.*,audio.*,related_articles.*&sort=-publish_date&filter[environment][like]=' + process.env.NODE_ENV + '&filter[publish_date][lte]=now' + (filter ? '&' + filter : '')).then(result => {
      result.data = result.data.filter(article => {
        if (!article.publish_date) {
          return true
        }

        const date = new Date(article.publish_date + (article.publish_time ? 'T' + article.publish_time : ''))

        return date.getTime() <= Date.now()
      })

      return Promise.resolve(result)
    })
  },
  getCategoryRelatedArticles (categories) {
    return this.getItems('articles_categories', 'filter[category_id][in]=' + categories.map(category => category.id).join(',')).then(resultRelations => {
      return this.getPublishedArticles('filter[id][in]=' + resultRelations.data.map(relation => relation.article_id).join(',')).then(resultArticles => {
        resultArticles.data.forEach(article => {
          resultRelations.data.filter(relation => relation.article_id === article.id).forEach(relation => {
            const category = categories.find(category => category.id === relation.category_id)

            if (category) {
              if (!category.articles) {
                category.articles = []
              }

              category.articles.push(article)
            }
          })
        })

        return Promise.resolve(categories)
      })
    })
  },
  getArticleRelatedCategories (ids) {
    return this.getItems('articles_categories', 'filter[article_id][in]=' + ids.join(',')).then(result => {
      return this.getPublishedCategories('filter[id][in]=' + result.data.map(relation => relation.category_id).join(','))
    })
  }
}
