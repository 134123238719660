<template>
  <StackLayout class="wrapper">
    <AgCaption light text="Keine Internetverbindung" />
    <Label class="text" text="Zur Zeit kann keine Verbindung zum Internet hergestellt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal." textWrap />
    <AgButton text="Schließen" @tap="close" @click="close" />
  </StackLayout>
</template>

<script>

export default {
  methods: {
    close () {
      this.$emit('done')
    }
  }
}

</script>

<style scoped native>

.wrapper {
  padding: 20;
}

.text {
  margin-bottom: 20;
}

</style>

<style scoped web>

.wrapper {
  padding: 20px;
}

.text {
  margin-bottom: 20px;
}

</style>
