<template>
  <div class="footer">
    <div class="headline">Wir sind für Euch da!</div>
    <div class="social-media">
      <template v-for="link in links">
        <a-link v-if="link.area.indexOf('footer-social-links') >= 0" :key="link.id" :class="link.icon ? ('icon-' + link.icon) : ''" :href="link.url" :target="link.new_window ? '_blank' : ''"></a-link>
      </template>
    </div>
    <div class="text-links">
      <template v-for="link in links">
        <a-link v-if="link.area.indexOf('footer-text-links') >= 0" :key="link.id" :href="link.url" :target="link.new_window ? '_blank' : ''">{{ link.name }}</a-link>
      </template>
    </div>
    <div class="paw icon-paw"></div>
  </div>
</template>

<script>

import ContentService from '../services/content'

export default {
  data: () => ({
    links: []
  }),
  mounted () {
    ContentService.getItems('links', 'filter[area][rlike]=%footer-%&filter[environment][like]=' + process.env.NODE_ENV).then(result => {
      this.links = result.data
    })
  }
}

</script>

<style scoped lang="scss">

.footer {
  position: relative;
  margin-top: 10px;
  padding: 20px;
  background-color: #014E9E;
  color: white;
  overflow: hidden;

  & > * {
    position: relative;
    z-index: 2;
  }

  .headline {
    font-family: 'FrutigerLTStd-Roman';
    font-size: 26px;
    text-align: center;
    margin-bottom: 20px;
  }

  .link-customer-portal {
    color: white;
    text-decoration: none;
    font-family: 'FrutigerLTStdCn';
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }

  .links {
    display: flex;
    padding: 20px 0;
    margin: 0 auto;
    max-width: 400px;

    a {
      width: 25%;
      color: white;
      text-align: center;
      text-decoration: none;
      background-color: teal;

      img {
        display: block;
        height: 30px;
        margin: 0 auto 5px auto;
      }
    }

    @media (min-width: 840px) {
      a img {
        height: 40px;
      }
    }
  }

  .text-links {
    text-align: center;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 30px;

    a {
      color: white;
      text-decoration: none;
      margin: 0 10px;
      white-space: nowrap;
    }
  }

  .social-media {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    margin: 0 auto;
    max-width: 260px;

    a {
      height: 30px;
      font-size: 30px;
      color: #ffffff;
      text-decoration: none;
      margin: 10px;
    }

    @media (min-width: 840px) {
      /*
      position: absolute;
      top: 70px;
      left: 50%;
      flex-direction: column;
      margin-left: 300px;
      */

      a {
        height: 40px;
        font-size: 40px;
      }
    }
  }

  .paw {
    display: none;

    @media (min-width: 840px) {
      display: block;
      position: absolute;
      z-index: 1;
      top: 100px;
      right: 50%;
      width: 300px;
      height: 300px;
      font-size: 300px;
      transform: scale(-1, 1);
      margin-right: 300px;
      opacity: 0.2;
    }
  }

  @media (min-width: 840px) {
    padding-bottom: 100px;
  }
}

</style>
