<template>
  <div class="wrapper">
    <a-box>
      <a-text v-html="data.content"></a-text>
      <a-form class="form" ref="form">
        <div class="row">
          <a-input label="Anrede" v-model="salutation" type="select" :options="salutationOptions" />
        </div>
        <div class="row">
          <a-input label="Vorname" v-model="firstName" />
          <a-input label="Nachname" v-model="lastName" />
        </div>
        <div class="row">
          <a-input label="Tierart" v-model="animalType" type="select" :options="animalTypeOptions" />
          <a-input label="Tiername" v-model="animalName" />
        </div>
        <div class="row">
          <a-input required label="E-Mail-Adresse" v-model="email" :rules="[rules.notEmpty, rules.email]" />
        </div>
      </a-form>
      <a-actions>
        <a-btn @click="goBack">Zurück</a-btn>
        <a-btn primary @click="submit">Anmelden</a-btn>
      </a-actions>
    </a-box>
  </div>
</template>

<script>

import validation from '../../helpers/validation'
import newsletterService from '../../services/newsletter'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    presetEmail: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    success: false,
    email: '',
    firstName: '',
    lastName: '',
    animalName: '',
    animalType: '',
    animalTypeOptions: [
      {
        label: 'Bitte wählen...',
        value: ''
      },
      {
        label: 'Katze',
        value: 'Katze'
      },
      {
        label: 'Hund',
        value: 'Hund'
      }
    ],
    salutation: '',
    salutationOptions: [
      {
        label: 'Bitte wählen...',
        value: ''
      },
      {
        label: 'Frau',
        value: 'Frau'
      },
      {
        label: 'Herr',
        value: 'Herr'
      }
    ],
    rules: {
      notEmpty: (v) => validation.notEmpty(v) || 'Darf nicht leer sein!',
      email: (v) => validation.email(v) || 'Entspricht nicht einer E-Mail-Adresse!'
    }
  }),
  watch: {
    presetEmail: {
      immediate: true,
      handler (to) {
        this.email = to
      }
    }
  },
  methods: {
    goBack () {
      this.$router.push({
        path: '/newsletter'
      })
    },
    submit () {
      if (this.$refs.form.validate()) {
        newsletterService.subscribe({
          email: this.email,
          firstname: this.firstName,
          lastname: this.lastName,
          animal_type: this.animalType,
          animal_name: this.animalName,
          salutation: this.salutation
        }).then(result => {
          this.$router.push({
            path: '/newsletter-anmeldung-erfolgreich'
          })
        }).catch(error => {
          alert(error)
        })
      }
    }
  },
  mounted () {
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
  }
}
</script>

<style lang="scss" scoped>

.wrapper {
  background-color: #F6FAFF;

  @media (min-width: 840px) {
    .form {
      max-width: 480px;
    }
  }
}

</style>
