<template>
  <div class="page">
    <layout-article :data="article" />
  </div>
</template>

<script>

import LayoutArticle from '../components/Layout/Article'

export default {
  components: {
    'layout-article': LayoutArticle
  },
  props: {
    article: {
      type: Object,
      default: () => ({})
    }
  }
}

</script>
