<template>
  <div class="download-list">
    <div v-for="download in downloads" :key="download.id">
      <div class="title">
        <h2>{{ download.title }}</h2>
      </div>
      <div class="image">
        <a-image :data="download.image" thumbnail="thumbnail" :alt="download.title" />
      </div>
      <div class="description" v-html="download.description"></div>
      <div class="actions">
        <a-btn primary :data-download="download.title" @click="load(download)"><span class="icon-download"></span> Download</a-btn>
      </div>
    </div>
  </div>
</template>

<script>

import ContentService from '../services/content'

export default {
  props: {
    ids: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    downloads: []
  }),
  watch: {
    ids: {
      immediate: true,
      handler (to) {
        ContentService.getItems('downloads', 'status=published&fields=*,image.*,file.*' + (to.length ? '&filter[id][in]=' + to.join(',') : '')).then(result => {
          this.downloads = result.data
        })
      }
    }
  },
  methods: {
    load (download) {
      window.open(download.file.data.full_url)
    }
  }
}

</script>

<style lang="scss" scoped>

.download-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: 100%;
    margin-top: 30px;
    background-color: #eee;
    border-radius: 10px;
    padding: 20px 20px 100px 20px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &:first-child {
      border-top: none;
    }

    .title {
      width: 100%;
    }

    .image {
      width: 30%;
      margin-top: 20px;
      justify-content: center;

      img {
        margin: 0 auto;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }

    .description {
      width: 70%;
      margin-top: 20px;
      padding-left: 20px;
      font-size: 16px;
      line-height: 22px;
    }

    .actions {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      margin-top: 20px;
      display: flex;
      justify-content: center;

      & > * {
        width: 100%;
      }
    }
  }

  @media (min-width: 840px) {
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: 48%;
    }
  }
}

</style>
