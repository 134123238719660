export default {
  showScreen () {
    window.__cmp('showScreen')
  },
  onChange (callback, immediate) {
    const interval = setInterval(() => {
      const cmp = window.__cmp

      if (typeof cmp === 'function') {
        cmp('addEventListener', ['consent', (event, data) => {
          callback(data.getCMPData())
        }, false])

        if (immediate) {
          cmp('getCMPData', null, (data) => {
            callback(data)
          })
        }

        clearInterval(interval)
      }
    }, 100)
  },
  onConsent (vendorName, callback, immediate) {
    this.onChange(data => {
      const vendor = data.vendorsList.find(vendor => vendor.name === vendorName)

      callback(vendor && !!data.vendorLI[vendor.id])
    }, immediate)
  }
}
