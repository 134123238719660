<template>
  <div :class="classes">
    <slot name="default" />
  </div>
</template>

<script>

export default {
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      const classes = ['text']

      if (this.small) {
        classes.push('small')
      }

      return classes
    }
  }
}

</script>

<style lang="scss" scoped>

.small {
  color: #014E9E;
  font-size: 12px;
}

</style>
