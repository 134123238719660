<template>
  <div>
    <img v-if="image" :src="src" :alt="alt" />
    <a-loader v-else text="Bild wird geladen..." />
  </div>
</template>

<script>

import ContentService from '../services/content'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => ({})
    },
    thumbnail: {
      type: String
    },
    alt: {
      type: String
    }
  },
  data: () => ({
    image: null
  }),
  computed: {
    src () {
      if (this.image) {
        if (this.thumbnail) {
          const result = this.image.data.thumbnails.find(thumbnail => thumbnail.key === this.thumbnail)

          if (result) {
            return result.url
          }
        }

        return this.image.data.full_url
      }

      return null
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (to) {
        if (to) {
          this.image = null

          ContentService.getFiles(to).then(result => {
            this.image = result.data

            this.$emit('loaded', result.data)
          })
        }
      }
    },
    data: {
      immediate: true,
      handler (to) {
        this.image = to
      }
    }
  }
}

</script>

<style lang="scss" scoped>

img {
  display: block;
}

</style>
