<template>
  <div :class="classes">
    <slot name="default" />
  </div>
</template>

<script>

export default {
  props: {
    center: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      const classes = ['box']

      if (this.center) {
        classes.push('center')
      }

      return classes
    }
  }
}

</script>

<style lang="scss" scoped>

.box {
  padding: 30px 15px 15px 15px;
  background-repeat: no-repeat;
  background-position: center bottom;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  &.spaced {
    padding-top: 40px;
    padding-bottom: 70px;
  }

  &:first-child {
    margin-top: 0;
  }

  &::v-deep li {
    text-align: left;
  }

  @media (min-width: 840px) {
    max-width: 1200px;

    &:not(.center) {
      text-align: left;
    }
  }

  @media (min-width: 900px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 960px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 1020px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: 1080px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (min-width: 1140px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (min-width: 1200px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media (min-width: 1260px) {
    padding-left: 90px;
    padding-right: 90px;
  }

  @media (min-width: 1320px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (min-width: 1380px) {
    padding-left: 110px;
    padding-right: 110px;
  }
}

</style>
