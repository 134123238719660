<template>
  <div class="video-frame" ref="frame">
    <iframe v-if="allowed" :src="src" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <div v-else>An dieser Stelle findest Du eigentlich ein Video, das aufgrund Deiner Cookie-Einstellungen ausgeblendet wird. Du kannst das Video über <a :href="href" target="_blank">diesen Link</a> außerhalb unserer Webseite aufrufen oder einfach <a @click="openCookieSettings">hier</a> Deine Cookie-Einstellungen anpassen.</div>
  </div>
</template>

<script>

import consent from '../helpers/consent'

export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    allowed: false
  }),
  computed: {
    src () {
      return this.id ? 'https://www.youtube.com/embed/' + this.id : ''
    },
    href () {
      return this.id ? 'https://www.youtube.com/watch?v=' + this.id : ''
    }
  },
  methods: {
    setHeight () {
      this.$refs.frame.style.height = Math.round(this.$refs.frame.offsetWidth * 0.5625) + 'px'
    },
    openCookieSettings () {
      consent.showScreen()
    }
  },
  mounted () {
    consent.onConsent('YouTube', to => {
      this.allowed = to
    }, true)
    this.setHeight()

    window.addEventListener('resize', () => {
      this.setHeight()
    })
  }
}

</script>

<style lang="scss" scoped>

.video-frame {
  background-color: #444;
  display: flex;
  justify-content: center;
  color: #ccc;
  align-items: center;
  text-align: center;

  a {
    color: #C0E1FF;
  }

  div {
    padding: 50px
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

</style>
