const colors = {
  primary: '#004e9e',
  highlight: '#3299ff',
  success: '#00bc8c',
  warning: '#f39c11',
  error: '#e84c3d',
  info: '#3598db',
  grey: '#C6C6C6',
  accent: '#ea4f53',
  darktrans: 'rgba(0, 0, 0, 0.2)'
}

export {
  colors
}
