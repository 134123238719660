<template>
  <div class="page">
    <component v-bind:is="layoutType" :data="page" :settings="page.layout.settings">
      <template v-slot:bottom>
        <a-btn primary @click="goToHome" v-if="page.links && page.links.find(l => l === 'home')">Zur Startseite</a-btn>
      </template>
    </component>
  </div>
</template>

<script>

import LayoutDefault from '../components/Layout/Default'
import LayoutHome from '../components/Layout/Home'
import LayoutNewsletterHome from '../components/Layout/NewsletterHome'
import LayoutNewsletterForm from '../components/Layout/NewsletterForm'
import LayoutSurvey from '../components/Layout/Survey'
import LayoutDownloads from '../components/Layout/Downloads'

export default {
  components: {
    LayoutDefault,
    LayoutHome,
    LayoutNewsletterHome,
    LayoutNewsletterForm,
    LayoutSurvey,
    LayoutDownloads
  },
  props: {
    page: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    layoutType () {
      if (this.page.layout.key) {
        return 'Layout' + this.page.layout.key.replace(/(?:^|\W+)(.)/g, (match, chr) => {
          return chr.toUpperCase()
        })
      }

      return 'LayoutDefault'
    }
  },
  methods: {
    goToHome () {
      this.$router.push('/')
    }
  }
}

</script>
