export default {
  dayNames: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
  monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  decimalPoint: ',',
  formatDateShort (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return String(date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + date.getFullYear()
  },
  formatDateMedium (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return String(date.getDate()).padStart(2, '0') + '. ' + this.monthNames[date.getMonth()] + ' ' + date.getFullYear()
  },
  formatDateLong (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return this.dayNames[date.getDay()] + ', ' + String(date.getDate()).padStart(2, '0') + '. ' + this.monthNames[date.getMonth()] + ' ' + date.getFullYear()
  },
  formatDateLocalISO (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return [date.getFullYear(), String(date.getMonth() + 1).padStart(2, '0'), String(date.getDate()).padStart(2, '0')].join('-')
  },
  formatTimeShort (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0')
  },
  formatTimeLong (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return this.formatTimeShort(date) + ':' + String(date.getSeconds()).padStart(2, '0')
  },
  formatTimeLocalISO (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return this.formatTimeLong(date)
  },
  formatDateTimeShort (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return this.formatDateShort(date) + ', ' + this.formatTimeShort(date)
  },
  formatDateTimeMedium (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return this.formatDateMedium(date) + ', ' + this.formatTimeShort(date)
  },
  formatDateTimeLong (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return this.formatDateLong(date) + ', ' + this.formatTimeLong(date)
  },
  formatDateTimeLocalISO (date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return this.formatDateLocalISO(date) + 'T' + this.formatTimeLocalISO(date)
  }
}
