import Vue from 'vue'
import Button from '../components/Button'
import Form from '../components/Form'
import Input from '../components/Input'
import Loader from '../components/Loader'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Headline from '../components/Headline'
import Text from '../components/Text'
import Box from '../components/Box'
import Actions from '../components/Actions'
import YouTubeVideo from '../components/YouTubeVideo'
import Image from '../components/Image'
import Share from '../components/Share'
import ExpertList from '../components/ExpertList'
import AudioList from '../components/AudioList'
import DownloadList from '../components/DownloadList'
import ArticleList from '../components/ArticleList'
import InstagramFeed from '../components/InstagramFeed'
import Link from '../components/Link'
import Checkbox from '../components/Checkbox'
import Icon from '../components/Icon'

Vue.component('a-btn', Button)
Vue.component('a-form', Form)
Vue.component('a-input', Input)
Vue.component('a-loader', Loader)
Vue.component('a-header', Header)
Vue.component('a-footer', Footer)
Vue.component('a-headline', Headline)
Vue.component('a-text', Text)
Vue.component('a-box', Box)
Vue.component('a-actions', Actions)
Vue.component('a-youtube-video', YouTubeVideo)
Vue.component('a-image', Image)
Vue.component('a-share', Share)
Vue.component('a-expert-list', ExpertList)
Vue.component('a-audio-list', AudioList)
Vue.component('a-download-list', DownloadList)
Vue.component('a-article-list', ArticleList)
Vue.component('a-instagram-feed', InstagramFeed)
Vue.component('a-link', Link)
Vue.component('a-checkbox', Checkbox)
Vue.component('a-icon', Icon)
