import axios from 'axios'
import store from '../store'
import AgOffline from '../components/Modal/Offline'
import AgReportError from '../components/Modal/ReportError'
import dialog from '../helpers/dialog'
import env from '../helpers/env'
import { name, version } from '../../package.json'

const api = axios.create({
  timeout: 10000
})

function statusValidation (list) {
  return status => (status >= 200 && status < 300) || list.indexOf(status) >= 0
}

function responseOk (response) {
  return response.status >= 200 && response.status < 300
}

function getEndpoint () {
  switch (store.state.apiMode) {
    case 'dead':
      return 'https://localhost/'

    case 'dev':
      return 'https://api-dev-03.agila.de/legacy/api/v1/portal/'

    case 'stg':
      return 'https://api-stg-03.agila.de/legacy/api/v1/portal/'

    default:
      return 'https://api-prd-03.agila.de/legacy/api/v1/portal/'
  }
}

function errorHandler (view, error) {
  if (error.response && error.response.status) {
    dialog.component(view, AgReportError, {
      type: 'API-Error',
      message: error.message,
      details: error
    })
  } else {
    axios.create().get(process.env.VUE_APP_DIAGNOSTIC_ENDPOINT).then(() => {
      dialog.component(view, AgReportError, {
        type: 'Server-Error',
        message: 'Endpoint not reachable',
        details: {
          url: getEndpoint()
        }
      })
    }).catch(() => {
      dialog.component(view, AgOffline)
    })
  }

  return 'Vorgang konnte nicht ausgeführt werden.'
}

api.interceptors.request.use(config => {
  config.headers = config.headers || {}

  config.headers['Client-Key'] = '4515a3f5-8b79-4c3f-8b19-a9150b7e7700'
  config.headers['Frontend-Name'] = name
  config.headers['Frontend-Version'] = version
  config.headers['Frontend-Platform'] = 'web'

  if (store.state.user.token) {
    config.headers.Authorization = 'Bearer ' + store.state.user.token
  }

  if (!env.isWeb) {
    config.headers['User-Agent'] = env.system.os + '/' + env.system.osVersion
  }

  config.baseURL = getEndpoint()

  return config
}, error => {
  console.log('Do something with request error')
  return Promise.reject(error)
})

api.interceptors.response.use(response => {
  return response
}, error => {
  error.handler = view => {
    return errorHandler(view, error)
  }

  return Promise.reject(error)
})

export {
  api,
  statusValidation,
  responseOk,
  getEndpoint,
  errorHandler
}
