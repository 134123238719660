import axios from 'axios'
import { version } from '../../package.json'

const connection = axios.create({
  baseURL: process.env.VUE_APP_DIAGNOSTIC_ENDPOINT,
  headers: {
    Authorization: 'Bearer ' + process.env.VUE_APP_DIAGNOSTIC_TOKEN
  }
})

export default {
  reportError (type, message, details, system, route, user) {
    return connection.post('?action=reportError', {
      type,
      message,
      details,
      system,
      route,
      user,
      version
    }).then(response => {
      return Promise.resolve(response.data)
    })
  }
}
