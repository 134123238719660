<template>
  <a-box class="menu-wrapper">
    <div class="articles-block">
      <div class="title">{{ primaryCategory.title }}</div>
      <div class="description">{{ primaryCategory.description }}</div>
      <div class="links" v-if="primaryCategory.articles">
        <template v-for="article in primaryCategory.articles">
          <div :key="article.id">
            <a-link :key="article.id" :href="article.path" @click="$emit('click', $event)">
              <span class="image">
                <a-image :data="article.image" thumbnail="thumbnail" />
              </span>
              <span class="chevron icon-chevron-right"></span>
              <span class="text">{{ article.title }}</span>
            </a-link>
          </div>
        </template>
      </div>
    </div>

    <div class="link-block">
      <div class="links">
        <template v-for="link in links">
          <a-link v-if="link.area.indexOf('menu-icon-links') >= 0" :key="link.id" :href="link.url" :target="link.new_window ? '_blank' : ''" @click="$emit('click', $event)">
            <span :class="'icon icon-' + link.icon"></span>
            <span class="chevron icon-chevron-right"></span>
            <span class="text">{{ link.name }}</span>
          </a-link>
        </template>
      </div>
    </div>

    <template v-for="category in categories">
      <div class="link-block" v-if="category.menu" :key="category.id">
        <div class="title">{{ category.title }}</div>
        <div class="links" v-if="category.articles">
          <template v-for="article in category.articles">
            <a-link :key="article.id" :href="article.path" @click="$emit('click', $event)">
              <span class="chevron icon-chevron-right"></span>
              <span class="text">{{ article.title }}</span>
            </a-link>
          </template>
        </div>
      </div>
    </template>

    <div class="link-block">
      <div class="title">Hilfreiche Links</div>
      <div class="links">
        <template v-for="link in links">
          <a-link v-if="link.area.indexOf('menu-helpful-links') >= 0" :key="link.id" :href="link.url" :target="link.new_window ? '_blank' : ''" @click="$emit('click', $event)">
            <span class="chevron icon-chevron-right"></span>
            <span class="text">{{ link.name }}</span>
          </a-link>
        </template>
      </div>
    </div>
  </a-box>
</template>

<script>

import ContentService from '../services/content'

export default {
  data: () => ({
    primaryCategory: {},
    categories: [],
    articles: [],
    links: []
  }),
  mounted () {
    ContentService.getItems('links', 'filter[area][rlike]=%menu-%&filter[environment][like]=' + process.env.NODE_ENV).then(result => {
      this.links = result.data
    })

    ContentService.getPublishedCategories().then(result => {
      this.categories = result.data

      ContentService.getCategoryRelatedArticles(this.categories).then(result => {
        this.primaryCategory = this.categories.shift()
      })
    })
  }
}

</script>

<style lang="scss" scoped>

.menu-wrapper {
  padding-top: 0 !important;

  a {
    color: #fff;
  }

  .title {
    font-family: 'FrutigerLTStdCn';
    font-size: 20px;
    line-height: 30px;
  }

  .description {
    font-size: 12px;
    opacity: 0.7;
  }

  .articles {

  }

  .links {
    & > * {
      display: flex;
      margin: 10px 0 0 0;
      align-items: center;

      .icon {
        font-size: 40px;
        margin: 0 15px 0 0;
      }

      .chevron {
        font-size: 10px;
        margin: 0 10px 0 0;
      }

      .text {
        font-size: 14px;
      }
    }
  }

  .articles-block {
    text-align: left;

    .links {
      display: flex;
      flex-wrap: wrap;

      & > * {
        margin-top: 20px;
        display: block;
        width: 50%;

        &:nth-child(odd) {
          padding-right: 10px;
        }

        &:nth-child(even) {
          padding-left: 10px;
        }

        .image {
          display: block;
        }
      }
    }
  }

  .link-block {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    text-align: left;
    padding: 30px 0;
  }

  .articles-block + .link-block {
    border-top: none;
  }

  @media (min-width: 840px) {
    padding-top: 50px !important;
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-top: 50px;
      width: 50%;
    }

    & > :nth-child(odd) {
      padding-right: 20px;
    }

    & > :nth-child(even) {
      padding-left: 20px;
    }

    & > :nth-child(1) {
      margin-top: 0;
      width: 70%;
    }

    & > :nth-child(2) {
      margin-top: 0;
      width: 30%;
      padding-top: 70px;
    }

    .articles-block {
      .links {
        & > * {
          width: 25%;
          padding-right: 20px;

          &:nth-child(odd) {
            padding-right: 20px;
          }

          &:nth-child(even) {
            padding-left: 0;
          }
        }
      }
    }
  }
}

</style>
