<template>
  <div :class="classes" @click="$emit('click', $event)">
    <div ref="icon" class="glyph" :style="'font-size: ' + fontSize + 'px; color: ' + color + ';'">{{ icon }}</div>
  </div>
</template>

<script>

import { colors } from '@/helpers/ui'

export default {
  props: {
    type: {
      type: String,
      default: 'questionCircle'
    },
    set: {
      type: String,
      default: 'fas'
    },
    size: {
      type: [Number, String],
      default: 20
    },
    color: {
      type: String,
      default: colors.primary
    }
  },
  data () {
    return {
      setReal: '',
      sets: {
        fa: {
          eye: '\uf06e',
          eyeSlash: '\uf070',
          questionCircle: '\uf059',
          chevronLeft: '\uf053',
          chevronRight: '\uf054',
          chevronUp: '\uf077',
          chevronDown: '\uf078',
          externalLink: '\uf35d',
          faq: '\uf086',
          contact: '\uf075',
          info: '\uf05a',
          home: '\uf015',
          contract: '\uf15c',
          bell: '\uf0f3',
          bars: '\uf0c9',
          check: '\uf00c',
          circle: '\uf111',
          checkCircle: '\uf058',
          dotCircle: '\uf192',
          exclamationTriangle: '\uf071',
          exclamationCircle: '\uf06a',
          infoCircle: '\uf05a',
          user: '\uf007',
          pen: '\uf304',
          comment: '\uf075',
          square: '\uf0c8',
          checkSquare: '\uf14a',
          cog: '\uf013',
          key: '\uf084',
          euroSign: '\uf153',
          university: '\uf19c',
          clone: '\uf24d',
          plus: '\uf067',
          times: '\uf00d',
          filePdf: '\uf1c1',
          fileImage: '\uf1c5',
          folderOpen: '\uf07c',
          ban: '\uf05e',
          sync: '\uf021',
          calendar: '\uf073',
          ellipsisV: '\uf142',
          undo: '\uf2ea',
          upload: '\uf093',
          download: '\uf019',
          cookie: '\uf564',
          angleDoubleUp: '\uf102'
        },
        fab: {
          instagram: '\uf16d',
          facebook: '\uf082'
        },
        agila: {
          logoSmall: '\ue904',
          logoDefault: '\ue905',
          logoSmallAlt: '\ue939',
          logoAlt: '\ue93a',
          logoCircle: '\ue93b',
          home: '\ue91f',
          imprint: '\ue920',
          contact: '\ue921',
          more: '\ue926',
          customerInfo: '\ue927',
          editContract: '\ue92d',
          printContract: '\ue92e',
          activities: '\ue913',
          contracts: '\ue91b',
          invoiceSubmitted: '\ue91c',
          submittedInvoices: '\ue91d',
          feePayed: '\ue911',
          animals: '\ue910',
          hps: '\ue914',
          ops: '\ue915',
          phv: '\ue916',
          tks: '\ue917',
          contractInfo: '\ue906',
          paw: '\ue907',
          bankData: '\ue908',
          invoices: '\ue909',
          faq: '\ue90a',
          dog: '\ue90b',
          cat: '\ue90c',
          account: '\ue90d',
          accountAlt: '\ue90e',
          contract: '\ue90f',
          captureInvoice: '\ue933',
          cam: '\ue934',
          submitInvoice: '\ue935',
          handledInvoice: '\ue936',
          submitCase: '\ue937',
          insuranceCase: '\ue938',
          call: '\ue93d',
          info: '\ue93c',
          health: '\ue93e',
          emergencyCall: '\ue93f'
        }
      }
    }
  },
  computed: {
    classes () {
      const classes = ['icon', 'set-' + this.iconSet]

      return classes.join(' ')
    },
    iconSet () {
      const type = this.type.split(':')

      if (type.length > 1) {
        return type[0]
      } else {
        return this.set
      }
    },
    icon () {
      const type = this.type.split(':').pop()
      const set = this.iconSet === 'far' || this.iconSet === 'fas' ? 'fa' : this.iconSet

      if (this.sets[set] && this.sets[set][type]) {
        return this.sets[set][type]
      }

      return this.sets.fa.questionCircle
    },
    fontSize () {
      return parseInt(this.size)
    }
  }
}

</script>

<style scoped lang="scss">

.icon .glyph {
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.icon.set-far .glyph {
  font-family: 'fa-regular-400', 'Font Awesome 5 Free';
  font-weight: 400;
}

.icon.set-fab .glyph {
  font-family: 'fa-brands-400', 'Font Awesome 5 Brands';
  font-weight: 400;
}

.icon.set-fas .glyph {
  font-family: 'fa-solid-900', 'Font Awesome 5 Free';
  font-weight: 900;
}

.icon.set-agila .glyph {
  font-family: 'agila';
  font-weight: 400;
}

</style>
