<template>
  <div class="layout-home">
    <a-image-header :tags="data.content_list ? data.content_list.tags : ''">
      <a-text v-html="data.content"></a-text>
      <slot name="bottom"></slot>
    </a-image-header>
    <a-box>
      <a-article-list big :articles="articles" position="2">
        <a-instagram-feed :limit="settings.instagramFeedLimit" />
      </a-article-list>
    </a-box>
  </div>
</template>

<script>

import ContentService from '../../services/content'
import ImageHeader from '@/components/ImageHeader'

export default {
  components: {
    'a-image-header': ImageHeader
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    articles: []
  }),
  mounted () {
    ContentService.getPublishedArticles().then(result => {
      this.articles = result.data
    })
  }
}

</script>

<style lang="scss">

.image-header{
  .text {
    p:first-child {
      margin: 0 0 20px 0;
      padding: 0 30px;

      img {
        width: auto;
        max-height: 140px;
      }
    }
  }
}

</style>
