<template>
  <div id="app">
    <header>
      <a-header :showMenu="settings.show_menu" />
    </header>
    <main>
      <router-view />
    </main>
    <footer>
      <a-footer />
    </footer>
  </div>
</template>

<script>

import ContentService from './services/content'

export default {
  data: () => ({
    settings: {}
  }),
  mounted () {
    ContentService.getItems('settings', 'filter[environment]=' + process.env.NODE_ENV).then(result => {
      if (result.data && result.data.length) {
        this.settings = result.data[0]
      }
    })
  }
}

</script>

<style lang="scss">

* {
  box-sizing: border-box;
  image-rendering: auto;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: 'Syntax LT';
  font-size: 16px;
  line-height: 24px;
  color: #3B3B3B;

  @media (min-width: 1080px) {
    font-size: 18px;
    line-height: 28px;
  }
}

a {
  color: #014E9E;
  text-decoration: none;
  cursor: pointer;
}

h1, h2 {
  font-family: 'FrutigerLTStdCn';
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #014E9E;
  margin: 0;

  span {
    display: block;
  }

  @media (min-width: 840px) {
    span {
      display: inline;
      margin-right: 7px;
    }
  }

  @media (min-width: 1080px) {
    font-size: 38px;
    line-height: 44px;
  }
}

h2 {
  font-size: 18px;
  line-height: 22px;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }

  @media (min-width: 1080px) {
    font-size: 20px;
    line-height: 24px;
  }
}

h1 + h2 {
  margin-top: 4px;
}

p,
ul,
ol {
  margin: 15px 0 0 0;

  &:first-child {
    margin-top: 0;
  }

  li {
    margin: 15px 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

ul {
  li {
    position: relative;
    list-style: none;

    &:before {
      font-family: 'AGILA-HC';
      font-size: 13px;
      color: #014E9E;
      position: absolute;
      left: -25px;
      top: 0;
      content: '\e911';
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.only-small-screens {
  @media (min-width: 840px) {
    display: none;
  }
}

.only-big-screens {
  display: none;

  @media (min-width: 840px) {
    display: inline;
  }
}

@media (min-width: 840px) {
  .float-left {
    float: left;
    margin: 7px 20px 0 -50px;
  }

  .float-right {
    float: right;
    margin: 7px 0 0 20px;
  }
}

@media (min-width: 1080px) {
  .float-left {
    margin-left: -50px;
  }

  .float-right {
    margin-right: -100px;
  }
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #014E9E99;
  border: 5px solid white;
  border-left: none;
}

::-webkit-scrollbar-thumb:hover {
  background: #014E9E;
}

</style>

<style scoped lang="scss">

#app {
  min-width: 320px;
  min-height: 100%;
}

main {
  padding: 5px;
  overflow-x: hidden;
}

header {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1210px;

  @media (min-width: 900px) {
    padding-left: 35px;
    padding-right: 35px;
  }

  @media (min-width: 960px) {
    padding-left: 45px;
    padding-right: 45px;
  }

  @media (min-width: 1020px) {
    padding-left: 55px;
    padding-right: 55px;
  }

  @media (min-width: 1080px) {
    padding-left: 65px;
    padding-right: 65px;
  }

  @media (min-width: 1140px) {
    padding-left: 75px;
    padding-right: 75px;
  }

  @media (min-width: 1200px) {
    padding-left: 85px;
    padding-right: 85px;
  }

  @media (min-width: 1260px) {
    padding-left: 95px;
    padding-right: 95px;
  }

  @media (min-width: 1320px) {
    padding-left: 105px;
    padding-right: 105px;
  }

  @media (min-width: 1380px) {
    padding-left: 115px;
    padding-right: 115px;
  }
}

footer {
  padding: 5px;
}

</style>
