<template>
  <StackLayout class="wrapper">
    <AgCard modal :caption="caption">
      <Label textWrap :text="text" />
      <AgButton v-if="state === 'success' || state === 'fail'" text="Schließen" @tap="close" @click="close" />
    </AgCard>
    <StackLayout v-if="state === 'initial' || state === 'loading'">
      <AgButton flat light embedded text="Was wird übermittelt?" :action="details ? 'chevronUp' : 'chevronDown'" @tap="showDetails" @click="showDetails" />
      <AgCard modal v-if="details" class="details">
        <GridLayout columns="30, *" rows="auto, auto, auto, auto, auto, auto">
          <AgIcon col="0" row="0" type="circle" size="8" verticalAlignment="top" />
          <Label col="1" row="0" textWrap text="Informationen zum aufgetretenen Fehler" />

          <AgIcon col="0" row="1" type="circle" size="8" verticalAlignment="top" />
          <Label col="1" row="1" textWrap text="Plattform und Version Ihres Geräts" />

          <AgIcon col="0" row="3" type="circle" size="8" verticalAlignment="top" />
          <Label col="1" row="3" textWrap text="Informationen zu Ihrem Kundenkonto" />
        </GridLayout>
      </AgCard>
      <AgCard modal>
        <AgButton :loading="state === 'loading'" text="Problem melden" @tap="send" @click="send" />
        <AgButton outlined text="Abbrechen" @tap="close" @click="close" />
      </AgCard>
    </StackLayout>
  </StackLayout>
</template>

<script>

import store from '../../store'
import env from '../../helpers/env'
import DiagnosticsService from '../../services/diagnostics'

export default {
  props: {
    data: {}
  },
  data () {
    return {
      state: 'initial',
      details: false
    }
  },
  computed: {
    caption () {
      switch (this.state) {
        case 'success':
          return 'Problem gemeldet'

        case 'fail':
          return 'Problem melden nicht möglich'
      }

      return 'Hoppla! Das sollte nicht passieren!'
    },
    text () {
      switch (this.state) {
        case 'success':
          return 'Vielen Dank für das Melden des Problems. Wir werden uns zeitnah um eine Lösung kümmern.'

        case 'fail':
          return 'Das Problem kann zur Zeit leider nicht gemeldet werden. Bitte wenden Sie sich telefonisch oder per E-Mail an unseren Kundendienst.'
      }

      return 'Wir entschuldigen uns für die Unannehmlichkeiten. Damit wir den Fehler möglichst schnell beheben können, klicken Sie bitte auf den Button „Problem melden“.'
    }
  },
  methods: {
    showDetails () {
      this.details = !this.details
    },
    send () {
      this.state = 'loading'

      DiagnosticsService.reportError(this.data.type, this.data.message, this.data.details, env.system, store.state.recentRoute, store.state.user).then(() => {
        this.state = 'success'
      }).catch(() => {
        this.state = 'fail'
      })
    },
    close () {
      this.$emit('done')
    }
  }
}

</script>

<style scoped native>

.wrapper .details .icon {
  margin-top: 7;
}

</style>

<style scoped web>

.wrapper .details .icon {
  margin-top: 7px;
}

</style>
