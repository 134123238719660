function notEmpty (v) {
  return String(v).length > 0
}

function numbersOnly (v) {
  return String(v).match(/^\d+$/) !== null
}

function hasNumber (v) {
  return String(v).match(/\d/) !== null
}

function minLength (v, n) {
  return String(v).length >= n
}

function maxLength (v, n) {
  return String(v).length <= n
}

function email (v) {
  // eslint-disable-next-line
  return String(v).toLowerCase().match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
  // return String(v).toLowerCase().match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null
}

function password (v) {
  return passwordMinLength(v) && passwordLetterCase(v) && hasNumber(v) && hasSpecialChar(v)
}

function passwordMinLength (v) {
  return minLength(v, process.env.VUE_APP_PASSWORD_MIN_LENGTH)
}

function passwordLetterCase (v) {
  return v.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/) !== null
}

function hasSpecialChar (v) {
  return v.match(/[!%&@#$^*?_~]/) !== null
}

function iban (v) {
  var lengths = {
    NO: 15,
    BE: 16,
    DK: 18,
    FI: 18,
    FO: 18,
    GL: 18,
    NL: 18,
    MK: 19,
    SI: 19,
    AT: 20,
    BA: 20,
    EE: 20,
    KZ: 20,
    LT: 20,
    LU: 20,
    CR: 21,
    CH: 21,
    HR: 21,
    LI: 21,
    LV: 21,
    BG: 22,
    BH: 22,
    DE: 22,
    GB: 22,
    GE: 22,
    IE: 22,
    ME: 22,
    RS: 22,
    AE: 23,
    GI: 23,
    IL: 23,
    AD: 24,
    CZ: 24,
    ES: 24,
    MD: 24,
    PK: 24,
    RO: 24,
    SA: 24,
    SE: 24,
    SK: 24,
    VG: 24,
    TN: 24,
    PT: 25,
    IS: 26,
    TR: 26,
    FR: 27,
    GR: 27,
    IT: 27,
    MC: 27,
    MR: 27,
    SM: 27,
    AL: 28,
    AZ: 28,
    CY: 28,
    DO: 28,
    GT: 28,
    HU: 28,
    LB: 28,
    PL: 28,
    BR: 29,
    PS: 29,
    KW: 30,
    MU: 30,
    MT: 31
  }

  const iban = String(v).replace(/\s/g, '').toUpperCase()
  const country = iban.slice(0, 2)

  if (iban.length !== lengths[country] || !iban.match(/^[\dA-Z]+$/)) return false

  const digits = (iban.slice(4) + country + iban.slice(2, 4)).replace(/[A-Z]/g, c => c.charCodeAt(0) - 55)
  var checksum = ''

  for (var offset = 0; offset < digits.length; offset += 7) {
    checksum = parseInt(String(checksum) + digits.slice(offset, offset + 7), 10) % 97
  }

  return checksum === 1
}

export default {
  notEmpty,
  numbersOnly,
  hasNumber,
  minLength,
  maxLength,
  email,
  password,
  passwordMinLength,
  passwordLetterCase,
  hasSpecialChar,
  iban
}
