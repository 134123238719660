import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      partner: {},
      token: '',
      image: '',
      loggedIn: false
    },
    apiMode: 'dev'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
